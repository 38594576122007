import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback
} from 'react';

import {
  Drawer,
  Button,
  Switch,
  Select,
  Tooltip,
  message,
  Spin,
  Modal,
  Input
} from 'antd';

import RefreshDataPrompt from 'components/RefreshDataPrompt';

import intl from 'react-intl-universal';

import { useDispatch } from 'react-redux';

import { connect } from 'dva';

import RefreshBounced from 'components/RefreshBounced';

import styles from './index.less';
import { Get, Post } from 'utils/request';

const { Option } = Select;

function BatchAgencies({
  batchAgenciesDrawerVisible,
  batchAgenciesDrawerClose,
  agencyManagementData,
  batchAgenciesList
}) {
  const dispatch = useDispatch();
  const [refreshBouncedVisible, setRefreshBouncedVisible] = React.useState();
  const [loadingStatus, setLoadingStatus] = React.useState(false);
  const [statusLoadingStatus, setStatusLoadingStatus] = React.useState(false);
  const [haveHandled, setHaveHandled] = React.useState(true);
  const [switchStatus, setSwitchStatus] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [error, setError] = useState(false);

  const [visibleSwitchMapping, setVisibleSwitchMapping] = React.useState(false);

  const temporarySwitchStatus = useRef();

  const agencyMapList = useMemo(() => {
    return agencyManagementData || [];
  }, [agencyManagementData]);

  const onChange = async checked => {
    temporarySwitchStatus.current = checked;
    setVisibleSwitchMapping(true);
  };

  const switchover = async checked => {
    if (!inputValue) {
      setError(true);
      return;
    }

    setSwitchStatus(checked);
    let delayBouncedStatus = true;

    let delayBounced = setTimeout(() => {
      if (delayBouncedStatus) {
        setStatusLoadingStatus(true);
      }

      clearTimeout(delayBounced);
    }, 500);

    try {
      const product_ids = batchAgenciesList.map(item => item.id);
      const isInvalid = !!agencyMapList.find(
        item => item.id === parseInt(inputValue)
      );

      if (!isInvalid) {
        const validAgencyIdRes = await Post('/merchant/agency', {
          data: { agency_id: parseInt(inputValue) }
        });
        if (validAgencyIdRes.code !== 2000 || !validAgencyIdRes.data) {
          setStatusLoadingStatus(false);
          delayBouncedStatus = false;
          setSwitchStatus(!checked);
          clearTimeout(delayBounced);
          setError(true);
          return;
        }
      }

      setError(false);

      let data = {
        product_ids: product_ids,
        store_id: localStorage.getItem('storeId'),
        status: checked,
        agency_id: parseInt(inputValue),
        agency_type: 5
      };

      const result = await Post('/prod/bind_batch', {
        data
      });

      if (result.code === 2010) {
        delayBouncedStatus = false;
        message.success('Succeed');
        dispatch({ type: 'global/getAgencyListFromMerchant' });
      } else {
        delayBouncedStatus = false;
      }
    } catch (error) {
      console.log(error);
    }
    setStatusLoadingStatus(false);
  };

  const onInputChange = e => {
    setHaveHandled(false);
    setInputValue(e.target.value?.replace(/[^0-9]/g, ''));
    setError(false);
  };

  const getNewAgencyManagementData = () => {
    dispatch({ type: 'global/getAgencyListFromMerchant' });
    setRefreshBouncedVisible(false);
  };

  const replaceChange = useCallback(async () => {
    if (!inputValue) {
      setError(true);
      return;
    }

    let delayBouncedStatus = true;

    let delayBounced = setTimeout(() => {
      if (delayBouncedStatus) {
        setLoadingStatus(true);
      }

      clearTimeout(delayBounced);
    }, 500);
    try {
      const product_ids = batchAgenciesList.map(item => item.id);

      const isInvalid = !!agencyMapList.find(
        item => item.id === parseInt(inputValue)
      );

      if (!isInvalid) {
        const validAgencyIdRes = await Post('/merchant/agency', {
          data: { agency_id: parseInt(inputValue) }
        });
        if (validAgencyIdRes.code !== 2000 || !validAgencyIdRes.data) {
          clearTimeout(delayBounced);
          setLoadingStatus(false);
          delayBouncedStatus = false;
          setError(true);
          return;
        }
      }

      setError(false);

      const result = await Post('/prod/bind_batch', {
        data: {
          product_ids: product_ids,
          store_id: localStorage.getItem('storeId'),
          status: switchStatus,
          agency_id: parseInt(inputValue),
          agency_type: 5
        }
      });

      if (result.code === 2010) {
        delayBouncedStatus = false;
        setHaveHandled(true);
        message.success('Succeed');
        dispatch({ type: 'global/getAgencyListFromMerchant' });
      } else {
        delayBouncedStatus = false;
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingStatus(false);
  }, [batchAgenciesList, switchStatus, inputValue]);

  return (
    <Drawer
      placement="right"
      closable
      width={868}
      onClose={() => {
        setSwitchStatus(false);
        setInputValue('');
        batchAgenciesDrawerClose();
      }}
      visible={batchAgenciesDrawerVisible}
    >
      <Spin
        size="large"
        spinning={statusLoadingStatus || loadingStatus}
        className={styles.location}
      >
        <div className={styles.drawerHeader} id="agencySwitchStatus">
          <div className={styles.title}>
            {intl.get('setting.agency.Agency')}
          </div>
          <div className={styles.subtitle}>
            {intl.get('setting.agency.agencyExplain')}
          </div>
          <div className={styles.agencyStatus}>
            <div className={styles.name}>
              <div className={styles.statusTitle}>
                {intl.get('mapping.status')}
              </div>
              <div>{intl.get('setting.agency.statusExplain')}</div>
            </div>
            <div className={styles.action}>
              <RefreshDataPrompt
                placement="bottomRight"
                visible={statusLoadingStatus && temporarySwitchStatus.current}
                id="agencySaveButton"
              >
                <Switch
                  onChange={onChange}
                  defaultChecked={false}
                  disabled={statusLoadingStatus}
                  checked={switchStatus}
                />
              </RefreshDataPrompt>
            </div>
          </div>
        </div>

        <div className={styles.table}>
          <div className={styles.title}>
            {intl.get('setting.agency.agencyInformation')}
          </div>
          <div className={styles.subtitle}>
            {/* {intl.get('setting.agency.selectAgentPrompt')}
            <a
              href="/app/settings?id=14"
              target="_blank"
              onClick={() => setRefreshBouncedVisible(true)}
            >
              &nbsp;{intl.get('setting.agency.toSetting')}&nbsp;
            </a>
            {intl.get('setting.agency.addAgency')} */}
            Select an Agency to map your Shopify products with. Enter the Agency
            ID provided by your agency to map your products.
          </div>
          <div className={styles.content}>
            <div className={styles.describe}>
              Please Input Agency ID For Your Selected{' '}
              <span>{batchAgenciesList?.length} Kinds</span> Products:
            </div>
            <div className={styles.select}>
              {/* <Select
                placeholder="Select Agency"
                onChange={activateSelect}
                value={selectStatus}
              >
                {agencyMapList?.map(ele => (
                  <Option value={ele.id} key={ele.id}>
                    {ele.agency_name}
                  </Option>
                ))}
              </Select> */}
              <Input
                style={{ width: 200 }}
                value={inputValue}
                onChange={onInputChange}
                placeholder="Agency ID"
                pattern="[0-9]*"
              />
              <div style={{ color: 'red' }}>
                {error
                  ? inputValue
                    ? 'Invalid Agency ID'
                    : 'Agency ID is required'
                  : null}
              </div>
            </div>
          </div>
        </div>
      </Spin>
      <div className={styles.base} id="agencySaveButton">
        <RefreshDataPrompt
          placement="topRight"
          visible={false}
          id="agencySaveButton"
        >
          <Button
            type="primary"
            onClick={replaceChange}
            loading={loadingStatus}
            disabled={
              !inputValue || haveHandled || loadingStatus || statusLoadingStatus
            }
          >
            SAVE
          </Button>
        </RefreshDataPrompt>
      </div>

      <RefreshBounced
        visible={refreshBouncedVisible}
        handleRefresh={() => setRefreshBouncedVisible(false)}
        buttonEvent={getNewAgencyManagementData}
      />

      <Modal
        visible={visibleSwitchMapping}
        title={
          temporarySwitchStatus.current
            ? intl.getHTML('mapping.open_new_mapping_title')
            : intl.getHTML('mapping.close_all_mapping_title')
        }
        className={styles.deleteTipModal}
        onCancel={() => setVisibleSwitchMapping(false)}
        footer={
          <>
            <Button
              className={styles.btn}
              onClick={() => {
                switchover(temporarySwitchStatus.current);
                setVisibleSwitchMapping(false);
              }}
            >
              {intl.getHTML('public.newconfirm')}
            </Button>
          </>
        }
      >
        {temporarySwitchStatus.current ? (
          <div>
            {intl.get('mapping.open_new_mapping', {
              switch: ''
            })}
          </div>
        ) : (
          <div>{intl.get('mapping.close_all_mapping')}</div>
        )}
      </Modal>
    </Drawer>
  );
}

export default connect(({ global }) => ({
  agencyManagementData: global.agencyManagementDataMerchant
}))(BatchAgencies);
