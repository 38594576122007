import React, { useState, useEffect } from 'react';
import { Select, Row, Col,Collapse } from 'antd';
import intl from 'react-intl-universal';
import styles from './index.less';

import { Get } from 'utils/request';

const { Panel } = Collapse;

function OrganizationNoEdit(props) {
  const {
    producteDetail:{
      detail: { product_type, tags, vendor },
      collections = []
    },
    disabled
  } = props;

  const [allCollection,setAllCollection] = useState([])
  const [collectionErr,setCollectionErr] = useState(false)
  const [storeName,setStoreName] = useState('')

  const cols = [];

  collections.forEach(item => {
    cols.push(item.collection);
  });

  const fetchData = async()=> {
    const collections_from_shopify = await Get('/api/v1/prod/collections',{data:{
      store_id:props.producteDetail.store_id,product_id:props.producteDetail.detail.id
    }},true)
    if(collections_from_shopify?.data?.cols){
      const list = collections_from_shopify.data?.cols?.map(item => {
        return item.title
      });
      setAllCollection(list)
    }
    if(collections_from_shopify?.code != 2000){
      setCollectionErr(true)
    }else{
      setCollectionErr(false)
    }
  }

  const refreshData = ()=>{
    fetchData();
  }
  
  useEffect(()=>{
    fetchData();
    const { stores = [] } = JSON.parse(localStorage.getItem('store') || '{}');
    setStoreName(stores?.find(item=>item.id === props?.producteDetail?.store_id)?.shop_name)
  },[])
  

  return (
    <div className={styles.box}>
        <Collapse defaultActiveKey={['0']} expandIconPosition="right">
          <Panel key={0} header={<div>
                <i class="material-icons notranslate">storefront</i>
                <span>{storeName}</span>
                </div>}>
            <div className={styles.title}>{intl.get('product_edit.collection')}:</div>
            <Select value={allCollection} mode="tags" disabled={disabled} />
            {collectionErr ? <div className={styles.tryAgain}>{intl.getHTML('product_edit.try_again_collections_text')} <span className={styles.btn} onClick={refreshData}>{intl.getHTML('product_edit.try_again_collections_fun')}</span></div> :null}
            <div className={styles.title}>{intl.get('product.type_title')}:</div>
            <Select
              value={product_type? [product_type] : []}
              disabled={disabled}
              mode="tags"
            />

            <div className={styles.title}>{intl.get('product_edit.tags')}:</div>
            <Select
              value={tags ? tags.split(',') : []}
              disabled={disabled}
              mode="tags"
            />

            <div className={styles.title}>{intl.get('product_edit.vendor')}:</div>
            <Select
              value={vendor ? [vendor] : []}
              disabled={disabled}
              mode="tags"
            />
          </Panel>
        </Collapse>
    </div>
  );
}

export default OrganizationNoEdit;
