import React, { Component } from 'react';
import { connect } from 'dva';
import { Modal, Form, Input, Button, Icon, Tooltip } from 'antd';
import intl from 'react-intl-universal';

import { emailRegExp } from 'utils/constants';
import { checkPassword } from '../../routes/User/validator';

import styles from './index.less';

const FormItem = Form.Item;

@connect(({ auth, loading, login }) => ({
  auth,
  userInfo: login.userInfo,
  submitting: loading.effects['auth/completePassWord']
}))
@Form.create()
export default class CompletePassWord extends Component {
  constructor(props) {
    super(props);
    this.contentDom = null;
  }

  setContentDom = dom => {
    this.contentDom = dom;
  };

  // 发送
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.dispatch({
          type: 'auth/completePassWord',
          payload: {
            data: values,
            callback: d => {
              if (d.code === 2000 || d.code === 2010) {
                location.reload();
              }
            }
          }
        });
      }
    });
  };

  // confirm 是否禁用
  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  render() {
    const {
      form: { getFieldDecorator, getFieldsError },
      submitting,
      userInfo
    } = this.props;

    const { email = undefined, need_reset_password = false } = userInfo;

    return (
      <Modal
        visible={need_reset_password}
        footer={false}
        maskClosable={false}
        closable={false}
        zIndex={1000}
      >
        <div className={styles.content} ref={this.setContentDom}>
          <h3 className={styles.title}>
            {intl.get('complete_passWord.title')}
          </h3>
          <p className={styles.dec}>{intl.getHTML('complete_passWord.tip')}</p>
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15 }}
            onSubmit={this.handleSubmit}
          >
            <FormItem
              label={intl.get('login.email')}
              extra={
                email ? (
                  <Tooltip
                    title={intl.get('complete_passWord.email_tip')}
                    getPopupContainer={() => this.contentDom}
                    placement="left"
                  >
                    <Icon type="question-circle" />
                  </Tooltip>
                ) : null
              }
            >
              {getFieldDecorator('email', {
                initialValue: email,
                rules: [
                  {
                    required: true,
                    message: intl.get('register.input_your_email')
                  },
                  {
                    pattern: emailRegExp,
                    message: intl.get('register.input_your_email')
                  }
                ]
              })(
                <Input
                  type="email"
                  placeholder={intl.get('complete_passWord.fill_email')}
                  autoComplete="new-password"
                  disabled={!!email}
                />
              )}
            </FormItem>
            <FormItem label={intl.get('login.password')}>
              {getFieldDecorator('password', {
                rules: [
                  {
                    validator: (rule, value, callback) => checkPassword(rule, value, callback)
                  }
                ]
              })(
                <Input.Password
                  type="password"
                  autoComplete="new-password"
                  placeholder={intl.get('complete_passWord.password_request')}
                />
              )}
            </FormItem>
            <FormItem>
              <Button
                className={styles.btn}
                type="primary"
                htmlType="submit"
                loading={submitting}
                disabled={this.hasErrors(getFieldsError())}
              >
                {intl.get('public.confirm')}
              </Button>
            </FormItem>
          </Form>
        </div>
      </Modal>
    );
  }
}
