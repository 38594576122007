import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { Select } from 'antd';
import { connect } from 'dva';

import { replaceUrlToHttps } from 'utils/utils';
// import SetShipping from 'routes/Setting/Dropshpper/Common/Shipping/Shipping';
import { getImportHistory } from '../../../services/productmy';

import styles from './ShippingDetail.less';
import AgencySetShipping from 'routes/Setting/Dropshpper/Common/Shipping/AgencySetShipping';

function AgencyShippingDetail({ id, data, ship_to, agencies }) {
  const [selectData, setSelectData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [selectValue, setSelectValue] = useState(undefined);
  // console.log(data, id)
  useEffect(() => {
    const agencyMapping = data?.prod?.ext?.agencies || {};
    // const mappingAgencyMap = {};
    // Object.values(agencyMapping).forEach(mappingItem => {
    //   if (!mappingAgencyMap[mappingItem.agency_id]) {
    //     mappingAgencyMap[mappingItem.agency_id] = {
    //       ...mappingItem,
    //       agency_name: agencies?.find(i => i.id === mappingItem.agency_id)
    //         ?.agency_name || 'Unknown Agency',
    //       product_id: data?.prod?.detail?.id
    //     };
    //   }
    // });

    // const mappingAgencyList = Object.values(mappingAgencyMap);

    const variantMap = {};
    const mappingAgencyList = [];
    Object.values(agencyMapping).forEach(mappingItem => {
      if (mappingItem.variant_id && !variantMap[mappingItem.variant_id]) {
        const item = {
          ...mappingItem,
          agency_name:
            agencies?.find(i => i.id === mappingItem.agency_id)?.agency_name ||
            'Unknown Agency',
          product_id: data?.prod?.detail?.id
        };
        mappingAgencyList.push(item);
      }
    });

    setProductData(mappingAgencyList);

    if (mappingAgencyList[0]) {
      setSelectData(mappingAgencyList[0]);
      setSelectValue(mappingAgencyList[0].variant_id);
    }
    // if (data && data.aliexpress_product_id) {
    //   setSelectData(data);
    // }
  }, [data]);

  //   useEffect(() => {
  //     if (!id) {
  //       return;
  //     }
  //     setLoading(true);

  //     // console.log(data, '----data-----');

  //   }, [id]);

  function handleSelectChange(key) {
    setSelectValue(key);
    const value = productData.find(i => i.variant_id === key);
    if (value) {
      setSelectData(value);
    }
  }

  return (
    <div className={styles.shippingTab}>
      <div className={styles.title}>
        {intl.get('product_edit.shipping_info', {
          platform: ''
        })}
      </div>
      <div className={styles.bot_t_scr}>
        {id ? (
          <div className={styles.selectWrap}>
            <Select
              suffixIcon={
                <img
                  className="img_btn"
                  src={require('../../../assets/newImportList/icon_expand_more-24px.png')}
                ></img>
              }
              showSearch
              optionFilterProp="label"
              style={{ width: '100%' }}
              onChange={handleSelectChange}
              value={selectValue}
              optionLabelProp="label"
              placeholder="Choose a Agency"
              loading={loading}
              getPopupContainer={triggerNode => triggerNode.parentNode}
            >
              {productData.map(item => (
                <Select.Option
                  key={item.variant_id}
                  value={item.variant_id}
                  label={`Variant: ${item.variant_title} (Agency: ${item.agency_name})`}
                >
                  <div className={styles.productItem}>
                    {/* <img src={replaceUrlToHttps(item.image_src)} alt="" /> */}
                    <span>{`Variant: ${item.variant_title} (Agency: ${item.agency_name})`}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </div>
        ) : null}
        {selectData ? (
          <AgencySetShipping
            prod={selectData}
            productShipTo={ship_to}
            selectable={false}
          />
        ) : null}
      </div>
    </div>
  );
}

export default connect(({ global }) => {
  console.log(global);
  return {
    agencies: global.agencyManagementDataMerchant
  };
})(AgencyShippingDetail);
