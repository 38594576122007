/*
 * Created Date: Monday March 22nd 2021
 * Author: William (cweiliang.me@gmail.com)
 */

import React from 'react';
import { Alert, Icon, Button } from 'antd';
import intl from 'react-intl-universal';

import useUpdatePriceCheck_mp from '../useUpdatePriceCheck_mp';
import styles from './PushNotice.less';

import appIcon from '../../../assets/app_icon.png';
import succ from 'assets/alert/pushsucc.png'
import error from 'assets/alert/pusherr.png'
import closesucc from 'assets/alert/closesucc.png'
import closeerr from 'assets/alert/closeerr.png'

function UpdatePriceNotice({
  reqId,
  onCheckDetail,
  onStart,
  onFinish,
  onPending,
  onError,
  pushState
}) {
  const [pushProcessVisible, setPushProcessVisible] = React.useState(true);
  const { cachedData, result, awaited, run } = useUpdatePriceCheck_mp({
    onSuccess: () => {
      console.log('onSuccess');
      onFinish();
    },
    onError: error => {
      console.log('onError');
      onError();
    }
  });

  React.useEffect(() => {
    if (reqId) {
      setPushProcessVisible(true);
      run();
    }
  }, [reqId]);


  React.useEffect(() => {
    if (result) {
      onPending(result);
    }
  }, [result, onPending]);

  function handleClose() {
    setPushProcessVisible(false);
    // localStorage.removeItem('push_task');
  }

  const closeAlertHandler = ()=>{
    setPushProcessVisible(false)
  }

  const counts = React.useMemo(() => {
    let ok = 0;
    let fail = 0;
    let awaitNumber = 0;

    if (result) {
      awaitNumber = result.total_product_num
      ok = result.finish_product_num
      return {
        ok,
        fail,
        awaitNumber
      };
    }

    return null;
  }, [result]);

  if (!result || pushState === 0) {
    return null;
  }

  return (
    <div style={{ marginBottom: 24 }}>
      {pushProcessVisible && (counts.ok || counts.fail || counts.awaitNumber) ? (
        <Alert
          className={awaited && counts.fail ? styles.awaitError : awaited ? styles.send : (counts?.fail ? styles.senderr : styles.alert )}
          type="warning"
          icon={
            awaited ? (
              <Icon type="loading" style={counts.fail ? {color:'#FF8F00'} : {color:'#2189E5'}}/>
            ) : (
              <span>
                <img src={counts?.fail ? error : succ}  />
              </span>
            )
          }
          showIcon
          afterClose={handleClose}
          message={
            awaited ? (
              <div className={counts.fail ? styles.awaitCountError : styles.awaitCount}>{counts.awaitNumber} &nbsp; {intl.get('product.update_desc_wait')}
            </div>
            ) : (
              <div className={styles.count}>
                {counts ? (
                  <>
                    {
                      counts.fail && counts.ok
                      ? 
                      <div className={styles.leftText}>
                        <span className={styles.ok}>
                          {intl.get('product.update_desc_ok')}&nbsp;
                          <i>{counts.ok}</i>
                        </span>
                        <div></div>
                        <span className={styles?.fail}>
                          {intl.get('product.update_desc_fail')}&nbsp;
                          <i>{counts.fail}</i>
                        </span>
                    </div> : counts.fail
                    ? 
                    <div className={styles.leftText}>
                      <span className={styles?.fail}>
                        {intl.get('product.update_desc_fail')}&nbsp;
                        <i>{counts.fail}</i>
                      </span>
                    </div>
                    :
                    <div className={styles.leftText}>
                      <span className={styles.ok} style={{color:"#4CAF50"}}>
                        {intl.get('product.update_desc_ok')}&nbsp;
                        <i style={{color:"#4CAF50"}}>{counts.ok}</i>
                      </span>
                    </div>
                    }
                    <div className={styles.rightBox}>
                      <span className={styles.closeimgcon} onClick={closeAlertHandler}>
                        {!awaited ? (!counts?.fail ? <img src={closesucc} alt="" /> : <img src={closeerr} alt="" />) : null }
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
            )
          }
        />
      ) : null}
    </div>
  );
}

export default UpdatePriceNotice;
