import React, { PureComponent } from 'react';
import { connect } from 'dva';
import {
  List,
  Button,
  Checkbox,
  Modal,
  Icon,
  Tooltip,
  message,
  Input,
  Progress,
  Table,
  Select,
  Form,
  Tabs,
  Dropdown,
  Spin,
  Menu,
  Drawer,
  Collapse
} from 'antd';

import copy from 'copy-to-clipboard';
import intl from 'react-intl-universal';
import qs from 'querystring';

import Ellipsis from 'components/Ellipsis';
import ReplaceMyProdcutEdit from './ReplaceProduct';

import orm from 'assets/agencies/orm.svg';
import ormB from 'assets/agencies/ormB.svg';

import Mapping from 'components/Mapping';
import CardPagination from 'components/CardPagination';
import ErrorCashbackCode from 'components/ErrorCashbackCode';
import AEDataAuthorization from 'components/AEDataAuthorization';
import ProductPoolBanner from 'components/ProductPoolBanner';
import DSersSpBanner from 'components/DSersSpBanner';
import CompletePassWord from 'components/CompletePassWord';
import { dispatchEventForGuide } from '../BeginnerGuide/util';
import OldUiOutBanner from 'components/OldUiOut/OldUiOutBanner';
import ProductErrorTip from 'components/OldUiOut/ProductErrorTip';
import FilterDrawer from 'components/FilterDrawer/FilterDrawer';
import MyProductSearchInput from './component/MyProductSearchInput';
import InputGuide from 'components/FilterDrawer/InputGuide';
import UpdatePrice from './Common/UpdatePrice';
import UpdatepriceNotice_mp from './component/UpdatepriceNotice_mp';
import { SearchInput } from 'features';

import { OrderAction } from 'actions';

import SplitProduct from './Common/SplitProduct.js';

import BatchAgencies from './component/BatchAgencies';

import MyProdcutEdit from './MyProdcutEdit.js';
import ManageProduct from './ManageProduct.js';
import Product from './Product.js';
import { Get, Post } from 'utils/request';
import PriceChange from '../Notification/PriceChange.js';
import SkuChange from '../Notification/SkuChange.js';

import styles from './AliExpressProdcut.less';
import styles2 from './Product.less';
import styles3 from './MyProduct.less';

import './MyProduct.css';

import { getCookie, getSupplierId, getSupplierUrl } from 'utils/utils';
import reactGaEvent from '../../utils/GAEvent';
import cloneDeep from 'lodash/cloneDeep';
import withTracker from '../../common/tracker.js';
import {
  loadAliExpressPortals,
  replaceUrlToHttps,
  getLanguage,
  isUrl
} from '../../utils/utils';
import getProductShowData from './util';
import { confirmLanguage } from '../../common/sysconfig';
import AdBanner from 'components/AdBanner';
import { aeUrlMatchReg, tmallUrlMatchReg } from '../../utils/constants';
import NewUserWithoutAeModal from '../../components/NewUserModal/NewUserWithoutAeModal';
// import replace_loading from '../../assets/myproduct/replace_loading.png';
import searchIcon from '../../assets/newImportList/icon-search.png';
import iconShop from '../../assets/myproduct/icon_shop.png';
import closeFilterIcon from '../../assets/newImportList/close-24px.png';
import clearsearch from 'assets/newImportList/clearsearch.svg';
import push_guide from 'assets/myproduct/push_guide.png';

import RefreshBounced from 'components/RefreshBounced';

import { CommonDataCollect } from 'utils/utils';

const { Search } = Input;
const { TabPane } = Tabs;
const { Panel } = Collapse;

/* eslint react/no-array-index-key: 0 */
@connect(({ productmy, loading, login, dropshippersetting, global }) => ({
  productmy,
  loading: loading.models.productmy,
  listLoading: loading.effects['productmy/list'],
  userInfo: login.userInfo,
  storeId: global.storeId,
  admitadCheckCode: dropshippersetting.admitadCheckCode,
  optionLoading: loading.effects['productmy/deputySupplierOption'],
  historyLoading: loading.effects['productmy/deputySupplierHistory'],
  saveLoading: loading.effects['productmy/optionSaveSku'],
  getCountryLoading: loading.effects['productmy/supplierCountry'],
  getShippingLoading: loading.effects['productmy/supplierShipping'],
  saveSupplierLoading: loading.effects['productmy/saveSupplierShipping'],
  updateProductLoading: loading.effects['productmy/update'],
  blackListLoading: loading.effects['productmy/blackList'],
  removeProductLoading: loading.effects['productmy/remove'],
  lang: global.lang,
  localAEInfo: global.localAEInfo,
  agencyManagementData: global.agencyManagementDataMerchant
}))
@Form.create()
@withTracker
export default class MyProduct extends PureComponent {
  mappingFromBanner = false; // 是否来自 banner 中的 mapping

  listLock = false;

  constructor(props) {
    super(props);

    this.state = {
      showDetail: false,
      checkedList: [],
      bannerList: [],
      checkedVisibleList: [],
      showVisible: false,
      visibleProgress: 1,
      showDeputySupplier: false,
      mappingProduct: null,
      productValue: '',
      isSync: false, // 订单是否正在更新
      showManageProduct: false, // 是否展示管理商品
      productKeys: '999',
      showSetVisible: false, // 设置商品到shopify弹窗是否可见
      showSplitProduct: false, //
      showPriceChange: false,
      showSkuChange: false,
      hideProductConfirmDisabled: true,
      showBlackProductConfirm: false,
      showRemoveProductConfirm: false,
      isAeProductUrl: false,
      isNewUserAeModalShow: false,
      supplyBannerData: [],
      supplyBannerExtraData: [],
      supplyBannerLoading: false,
      showSupplyBanner: true,
      removeVisible: false,
      ids: [],
      isMany: '',
      replaceId: '',
      eventId: [],
      replaceStatus: 'none',
      showLoadingReplace: [],
      loadingData: [],
      checkDelete: false,
      filterProductVisible: false,
      filterByHidden: [],
      filterByNotification: [],
      filterByAgency: [],
      filterByAgencyShow: [],
      filterByMapping: [],
      filterByMappingShow: [],
      filterByShopifyDelete: [],
      filterByHiddenShow: [],
      filterByNotificationShow: [],
      filterByShopifyDeleteShow: [],
      productNums: {
        all_num: 0,
        ae_num: 0,
        tm_num: 0,
        unmap_num: 0,
        agency_count: 0
      },
      updateModalVisible: false,
      ifUseUpdate: false,
      updateReqId: undefined,
      updateLoading: false,
      updateLoadingProducts: [],
      showErrorTip: false,
      guideIndex: 0,
      changeAe: false,
      aeUrl: '',
      aeUrlError: false,
      aeLoading: false,
      aeDetails: {},
      newProductDetails: {},
      replaceDrawer: false,
      replaceStatus1: false,
      replaceLoading: true,
      need_product: {},
      refreshBouncedVisible: false,
      selectData: [],
      replaceSelectValue: 2,
      supplierUrlError: false,

      batchAgenciesDrawer: false // 批量修改Agencies mapping 抽屉
    };
  }

  componentWillMount() {
    this.cat = localStorage.getItem('storeId') || '';

    if (this.getQueryString('type')) {
      // window.open('https://home.aliexpress.com/dropshipper/ds_center.htm')
      window.location =
        'https://home.aliexpress.comx/dropshipper/join_drop_shipper.htm';
    }
    if (localStorage.getItem('shopifyProductID')) {
      this.searchKey = localStorage.getItem('shopifyProductID');
      this.setState({
        productValue: localStorage.getItem('shopifyProductID')
      });
    }
    if (localStorage.getItem('produstKey')) {
      this.changeTabs(localStorage.getItem('produstKey'));
      this.setState({
        productKeys: localStorage.getItem('produstKey')
      });
      localStorage.removeItem('produstKey');
    }
  }

  componentDidMount() {
    this.getStoreList(this.props.storeId);

    // this.AdBlockStatus(this.props.userInfo);
    this.DeepLinkLoad(this.props);

    this.timer = setInterval(() => this.checkReplaceStatus(), 5000);
    this.getInfoData();

    const { agencyManagementData } = this.props;
    this.setState({
      selectData: agencyManagementData
    });
  }

  componentWillReceiveProps(newProps) {
    const { selectData } = this.state;

    if (newProps.agencyManagementData.length !== selectData.length) {
      this.setState({
        selectData: newProps.agencyManagementData
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { showDeputySupplier, eventId } = this.state;
    const { storeId, productmy } = this.props;

    if (eventId !== prevState.eventId) {
      this.handleDataLoading();
    }

    if (
      productmy.data &&
      productmy.data !== prevProps.productmy.data &&
      productmy.data.prods &&
      productmy.data.prods.length > 0 &&
      !this.listLock
    ) {
      // 通知 guide
      this.listLock = true;
      dispatchEventForGuide({
        type: 'product',
        payload: { category: 'rendered', data: productmy.data.prods[0] }
      });
    }

    if (storeId !== prevProps.storeId) {
      // do something, 当 storeId 变化
      this.searchKey = '';
      // 一键mapping广告接口404处理 暂时注释这部分代码
      // this.handleFetchBannerData();
      this.changeStore(storeId);
    }

    if (
      showDeputySupplier !== prevState.showDeputySupplier &&
      !showDeputySupplier
    ) {
      if (this.mappingFromBanner) {
        // this.handleFetchBannerData(false);
      }
    }
    this.setState({
      guideIndex: this.getShowGuideHandler()
    });
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: 'global/changeSelectStoreIsDisabled',
      payload: false
    });
    this.timer = null;
  }

  getIfUseUpdate = async () => {
    const res = await Get('/prod/my_product/price/refresh', {
      data: {
        store_id: this.props.storeId
      }
    });
    if (res && res.data && res.data?.visible) {
      this.setState({
        ifUseUpdate: true
      });
    } else {
      this.setState({
        ifUseUpdate: false
      });
    }
  };

  checkReplaceStatus = () => {
    const eventId = JSON.parse(localStorage.getItem('replace_event_id'));
    if (!eventId?.length) {
      this.timer = null;
      return;
    }
    const res = [];
    if (eventId?.length) {
      eventId?.forEach(item => {
        res.push(item.eventId);
      });
    }

    this.setState(
      {
        eventId: res
      },
      () => {
        if (this.state.eventId?.length > 0) {
          this.getReplaceTime();
        }
      }
    );
  };

  getInfoData = () => {
    this.props.dispatch({
      type: 'guidance/getInfo',
      payload: {
        callback: d => {
          if (d.code === 2000) {
            this.setState({
              checkDelete: !d.data.is_after_go_live
            });
          }
        }
      }
    });
  };

  // eslint-disable-next-line
  handleFetchBannerData = async (showLoading = true) => {
    const { storeId } = this.props;
    if (showLoading) {
      this.setState({ supplyBannerLoading: true });
    }

    try {
      const res = await Get(
        `/prod/propool/spread/myproduct?store_id=${storeId}`
      );

      if (res && res.code === 2000) {
        if (res.data && res.data.prods) {
          const tmp = getProductShowData(
            { prods: res.data.prods.slice(0, 4), alis: res.data.alis },
            storeId
          );

          const formatData = {};
          if (res.data.prods) {
            res.data.prods.forEach(p => {
              const bannerItem = res.data.spreads.find(
                s =>
                  s.store_id === p.store_id && p.detail.id === s.sp_product_id
              );
              formatData[`${p.store_id}_${p.detail.id}`] = bannerItem;
            });
          }

          this.setState({
            supplyBannerData: tmp,
            supplyBannerExtraData: formatData
          });
        } else {
          this.setState({
            supplyBannerData: [],
            supplyBannerExtraData: []
          });
        }
      }
    } catch (error) {
      console.log('handleFetchBannerData error:', error);
    }

    if (showLoading) {
      this.setState({ supplyBannerLoading: false });
    }
  };

  handleBannerItemClick = (id, item) => {
    this.props.dispatch({
      type: 'global/showOneClickMapping',
      payload: item
    });

    this.updateBannerVisible(id);
  };

  /**
   * 1. 传 id，删除某一个商品
   * 2. 不传，删除整个 banner
   */
  updateBannerVisible = async id => {
    const { storeId } = this.props;
    const params = { store_id: storeId };

    if (id) {
      params.sp_product_id = id;
    }

    try {
      const res = await Post('/prod/propool/spread/myproduct', {
        data: params
      });
    } catch (error) {
      console.log('handleBannerClose error:', error);
    }
  };

  handleBannerClose = () => {
    this.setState({ showSupplyBanner: false });
    this.updateBannerVisible();
  };

  AdBlockStatus = user => {
    let blocked = 1;
    if (document.getElementById('2a074d356f827ecff5838d210848adf8')) {
      blocked = 0;
    }
    this.props.dispatch({
      type: 'productmy/checkAdBlockStatus',
      payload: {
        data: {
          status: blocked,
          userid: user.user_id
        }
      }
    });
  };

  // 只要开启cashback,加载速卖通click地址
  DeepLinkLoad = ({ userInfo, admitadCheckCode }) => {
    loadAliExpressPortals(
      userInfo,
      admitadCheckCode,
      this.props.localAEInfo?.affiliateStatus
    );
  };

  // 获取商店列表
  getStoreList = storeId => {
    const stores = this.props.userInfo.stores || [];

    this.storeData = stores;
    this.realStoreData = stores.filter(
      item =>
        item.shop_name != 'Importlist' && item.shop_name != 'Imported Orders'
    );

    if (this.realStoreData.length === 0) {
      this.hasRealStore = false;
    }

    this.getBannerList();
  };

  getReplaceTime = () => {
    this.props.dispatch({
      type: 'productmy/getReplaceTime',
      payload: {
        data: {
          event_ids: this.state.eventId
        },
        callback: d => {
          this.handleReplaceTime(d.data);
        }
      }
    });
  };

  handleReplaceTime = data => {
    const { loadingData } = this.state;
    data?.forEach(item => {
      loadingData?.forEach(items => {
        if (item.status == true && items.eventId == item.event_id) {
          items.status = 'success';
        }
        if (item.err_msg && items.eventId == item.event_id) {
          items.status = 'error';
        }
      });

      if (item.status || item.err_msg) {
        let local = JSON.parse(localStorage.getItem('replace_event_id'));

        const newLocal = local.filter(items => items.eventId !== item.event_id);

        localStorage.setItem('replace_event_id', JSON.stringify(newLocal));

        this.setState(
          {
            replaceId: '',
            loadingData: loadingData
          },
          () => {
            this.getProductList();
          }
        );
      }
    });
  };

  getBannerList = async () => {
    try {
      const storeIds = this.realStoreData.map(item => {
        return item.id;
      });
      const params = {
        data: {
          store_ids: storeIds
        }
      };
      const res = await Get('/prod/propool/ad/list', params);
      if (res && res.code === 2000) {
        const bannerList = res.data;
        res.data.forEach(item => {
          item.isBanner = true;
        });
        this.setState({
          bannerList
        });
      }
    } catch (error) {
      console.log('fetch error:', error);
    }
    this.getProductList();
  };

  getProductList = type => {
    const {
      filterByNotification,
      filterByMapping,
      filterByHidden,
      filterByShopifyDelete,
      filterByAgency
    } = this.state;
    if (this.storeData.length === 0) {
      return;
    }

    if (
      type == 'filter' &&
      !filterByNotification?.length &&
      !filterByMapping.length &&
      !filterByHidden &&
      !filterByShopifyDelete &&
      !filterByAgency.length
    ) {
      return;
    }

    if (type === 'filter') {
      this.setState({
        filterProductVisible: false
      });
    }

    let str = `${filterByNotification[0]}`;
    if (filterByNotification.length) {
      filterByNotification.forEach((i, index) => {
        if (index != 0) {
          str += `&notification[]=${i}`;
        }
      });
    }

    let strMapping = `${filterByMapping[0]}`;
    if (filterByMapping.length) {
      filterByMapping.forEach((i, index) => {
        if (index != 0) {
          strMapping += `&mapping[]=${i}`;
        }
      });
    }

    let strAgencies = `${filterByAgency[0]}`;
    if (filterByAgency.length) {
      filterByAgency.forEach((i, index) => {
        if (index != 0) {
          strAgencies += `&agencies[]=${i}`;
        }
      });
    }

    const params = {
      is_pub: this.productStatus,
      platform_type: 0,
      supplier_type: this.productPlatfromType,
      current: this.currentPage,
      page_size: this.pageSize,
      title: encodeURIComponent(this.searchKey),
      is_ver: this.verified,
      store_id: localStorage.getItem('storeId'),
      'notification[]': str ? str : '',
      'mapping[]': strMapping ? strMapping : '',
      'agencies[]': strAgencies ? strAgencies : '',
      hidden: filterByHidden,
      shopify_delete: filterByShopifyDelete
    };
    if (qs.parse(location.search.slice(1)).id) {
      params.shopify_pid = qs.parse(location.search.slice(1)).id;
    }

    if (str == 'undefined') {
      delete params['notification[]'];
    }
    if (strMapping == 'undefined') {
      delete params['mapping[]'];
    }

    if (strAgencies == 'undefined') {
      delete params['agencies[]'];
    }
    if (!window.IS_UPDATE_IMPORT_PRODUCT) {
      params.untimeliness = true;
    } else {
      delete params.untimeliness;
    }
    if (window.DS_BEGINNER_GUIDE_ACTIVE) {
      Object.assign(params, { is_tutorial: 1 });
    }

    this.props.dispatch({
      type: 'productmy/list',
      payload: {
        data: params,
        callback: res => {
          this.checkedVisibleList = {};
          this.saveStatus = true;
          this.setState({
            checkedList: [],
            bannerList: this.searchKey?.length ? [] : this.state.bannerList,
            // filterByNotification:[],
            // filterByHidden:[],
            // filterByShopifyDelete:[],
            // filterProductVisible:false,
            productNums: {
              all_num: res.data.all_count,
              ae_num: res.data.ae_count,
              tm_num: res.data.tmall_count,
              unmap_num: res.data.unmapped_count,
              agency_count: res.data.agency_count
            }
          });
        }
      }
    });

    localStorage.removeItem('shopifyProductID');
  };

  setShowVisibleData = () => {
    // 显示批量上架弹窗
    const list = Object.values(this.checkedVisibleList);
    list.forEach(item => {
      item.visibleStatus = 'loading';
      item.visibleResult = '';
    });
    this.setState({
      showVisible: true
    });
    return list;
  };

  // 批量上架商品
  loopVisiable = ids => {
    ids = Object.keys(this.checkedVisibleList);
    const len = ids.length;
    let index = 0;

    const list = this.setShowVisibleData();
    this.setState({
      checkedVisibleList: list
    });

    const visibleList = cloneDeep(list);

    const sub = () => {
      this.props.dispatch({
        type: 'productmy/status',
        payload: {
          data: {
            is_pub: this.alsoOnline ? 1 : 2,
            product_id: ids[index]
          },
          cancelMessage: true,
          callback: data => {
            if (data.code === 2000 || data.code === 2010) {
              visibleList[index].visibleStatus = 'check-circle';
              this.visibleIds.push(visibleList[index].id);
            } else {
              visibleList[index].visibleStatus = 'close-circle-o';
              visibleList[index].visibleResult = data.msg;
              this.visibleFailed = true;
            }
            index += 1;
            this.setState({
              checkedVisibleList: visibleList,
              visibleProgress: this.getPurchaseProgress(len, index)
            });
            if (index < len) {
              sub();
            } else {
              this.visibleAll = true;
              this.alsoOnline = false;
              if (this.visibleFailed == false) {
                message.success(intl.get('product.visible_succ'));
              }
            }
          }
        }
      });
    };
    sub();
  };

  getPurchaseProgress = (all, current) => {
    // 获取上架进度条长度
    return Math.ceil((current / all) * 100);
  };

  subPublishToShopify = () => {
    this.setState({
      showSetVisible: false
    });
    if (this.publishStatus === null) {
      this.loopVisiable(this.publishId);
    } else {
      this.visible(this.publishId, this.publishStatus);
    }
  };

  // 发送上架
  visible = (ids, status) => {
    this.props.dispatch({
      type: 'productmy/status',
      payload: {
        data: {
          is_pub: this.alsoOnline ? 1 : 2,
          product_id: ids[0]
        },
        cancelMessage: true,
        callback: data => {
          this.alsoOnline = false;
          if (data.code === 2000 || data.code === 2010) {
            message.success(intl.get('product.visible_succ'));
            this.setState({
              checkedList: []
            });
          } else {
            message.error(data.msg, 6);
          }
        }
      }
    });
  };

  // 根据速卖通url抓取产品详情
  addProduct = e => {
    if (this.state.productValue) {
      this.Echo = true;
    } else {
      this.Echo = false;
    }
    if (aeUrlMatchReg.test(e.trim())) {
      message.info(intl.getHTML('product.search_msg_for_migrate'));
      return;
    }

    this.currentPage = 1;
    this.searchKey = e.trim();
    this.getBannerList();

    // this.setState({
    //   productValue: ''
    // });
  };

  initListMap(list) {
    this.listMap = {};
    list.forEach(item => {
      this.listMap[item.id] = item;
    });
  }

  copyLink = item => {
    const prod = this.listMap[item.id];
    if (!prod.is_pub) {
      message.error(intl.get('product.copy_first_pushed'));
      return;
    }

    const stores = this.props.userInfo.stores || [];
    const storeId = localStorage.getItem('storeId');
    let domain = '';
    let storeName = '';

    stores.forEach(item => {
      if (item.id == storeId) {
        domain = item.domain;
        storeName = item.shop_name;
      }
    });

    domain = domain.includes('myshopify.com')
      ? domain
      : `${storeName}.myshopify.com`;

    let flag = false;
    let shopifyProductDetailUrl = '';
    let storeUrl = '';

    storeUrl = `https://${domain}/products/${prod.prod.detail.handle}`; // shopify商店商品详情
    shopifyProductDetailUrl = `https://${domain}/admin/products/${prod.prod.detail.id}`; // shopify管理后台商品编辑页
    flag = copy(storeUrl);

    if (flag) {
      message.success(intl.get('product.succ_copy'));
      window.open(shopifyProductDetailUrl, '_blank');
    } else {
      message.error(intl.get('product.copy_failed'));
    }
  };

  updateMyProduct = (id, values, isPublish) => {
    this.props.dispatch({
      type: 'productmy/update',
      payload: {
        data: values,
        message: {
          success: intl.get('product.update_succ')
        },
        callback: data => {
          if (data.code === 2010 || data.code === 2000) {
            this.hideDetail();
            if (isPublish) {
              this.showSetVisibleFn([id], values.status);
            }
          }
        }
      }
    });
  };

  deleteConfirmWord = ''; // 删除商品需要用户输入confirm
  deleteProductIds = null;

  inputDeleteConfirm = e => {
    this.deleteConfirmWord = e.target.value;
    this.setState({
      showErrorTip:
        e.target.value &&
        e.target.value.trim().toLocaleLowerCase() !==
          confirmLanguage[this.currentLanguage]
    });
  };

  remove = () => {
    const { checkDelete } = this.state;
    if (
      this.deleteConfirmWord.trim().toLocaleLowerCase() !==
      confirmLanguage[this.currentLanguage]
    ) {
      return;
    }
    // if (this.deleteConfirmWord.trim().toLocaleLowerCase() !== 'confirm') {
    //   return;
    // }
    if (this.state.showDetail) {
      this.setState({
        showDetail: false
      });
    }

    this.props.dispatch({
      type: 'productmy/remove',
      payload: {
        data: {
          product_ids: this.deleteProductIds,
          del_dsers_product_only: !checkDelete
        },
        callback: data => {
          if (data.code === 2010) {
            this.setState({
              showRemoveProductConfirm: false,
              removeVisible: false
            });
            this.getProductList();
            this.props.dispatch({
              type: 'topBtn/notificationUnreadLength',
              payload: {
                cancelMessage: true
              }
            });
          }
        }
      }
    });
  };

  /**
   * @param isMany 1 || undefined. 如果是1说明是批量删除
   */
  removeProduct = (ids, isMany) => {
    this.deleteConfirmWord = '';
    this.deleteProductIds = ids;

    this.setState(
      {
        ids: ids,
        isMany: isMany,
        showRemoveProductConfirm: true
      },
      () => {
        console.log(this.state.checkDelete, 'checkdelete');
      }
    );
  };

  queren = () => {
    this.setState({
      showRemoveProductConfirm: true
    });
  };

  hideRemoveProductConfirm = () => {
    this.setState({
      showRemoveProductConfirm: false,
      showErrorTip: false
    });
  };

  changeTabs = key => {
    this.productPlatfromType = key == '999' ? 0 : key;
    this.currentPage = 1;
    this.checkedVisibleList = {};
    this.setState(
      {
        checkedList: [],
        productKeys: key,

        filterByHidden: [],
        filterByNotification: [],
        filterByAgency: [],
        filterByShopifyDelete: [],
        filterByMapping: [],
        filterByMappingShow: [],
        filterByHiddenShow: [],
        filterByNotificationShow: [],
        filterByShopifyDeleteShow: [],
        filterByAgency: [],
        filterByAgencyShow: []
      },
      () => {
        this.getProductList();
      }
    );
  };

  currentPage = 1;
  pageSize = 20;
  searchKey = '';
  currentProdcut = {};
  listMap = {};
  checkedStockState = 1;
  checkedVisibleList = {};
  visibleAll = false;
  visibleFailed = false;
  visibleIds = [];
  cat = '';
  productStatus = 1; // 有没有推到shopify  0 all  1 pushed   2 unpushed
  verified = 0;
  productPlatfromType = 0; // 筛选的产品类型 ae => 2 other => 1 all => 0  tmall => 4
  hasRealStore = true; // 是否绑定了店铺
  storeData = []; // 商店列表
  realStoreData = [];
  currentLanguage = getLanguage();
  saveStatus = false; // 是否保存筛选条件
  filterByHiddenCopy = [];
  filterByNotificationCopy = [];
  filterByAgencyCopy = [];
  filterByShopifyDeleteCopy = [];
  Echo = false;

  // 编辑跳转
  showDetail = item => {
    this.currentProdcut = JSON.parse(JSON.stringify(item));
    this.setState({
      showDetail: true
    });
    this.props.dispatch({
      type: 'global/changeSelectStoreIsDisabled',
      payload: true
    });
  };

  hideDetail = () => {
    this.setState({
      showDetail: false
    });
    this.props.dispatch({
      type: 'global/changeSelectStoreIsDisabled',
      payload: false
    });
  };

  changePager = (page, pageSize) => {
    this.currentPage = page;
    this.pageSize = pageSize;
    this.getProductList();
  };

  // 商品列表选中更改
  changeCheck = (value, list) => {
    list.forEach(item => {
      if (item.is_black_list === 1 && value.includes(item.id)) {
        this.checkedVisibleList[item.id] = item;
      } else {
        delete this.checkedVisibleList[item.id];
      }
    });
    this.setState({
      checkedList: value
    });
  };

  // 搜索框输入值变化
  changeSearchKey = e => {
    if (!e?.target?.value) {
      this.searchKey = e?.target?.value || '';
      this.getProductList();
    }
    this.setState({
      productValue: e?.target?.value || '',
      isAeProductUrl: aeUrlMatchReg.test(e?.target?.value || '')
    });
  };

  showDeputySupplier = (item, fromBanner = false, isRead, product) => {
    // 交互打点
    CommonDataCollect({
      user_id: this.props.userInfo.user_id,
      sale_platform: 'shopify',
      supply_platform: 'aliexpress',
      trans_from: 'importlist click add card tags button',
      action: 'click_add_card_tags_button',
      event_type: 'interactive'
    });

    if (isRead) {
      this.setIsRead(product);
    }
    // 检测是否是新用户尚未连接AE   迁移流程/普通流程都去掉（和产品已经确认）
    //if (this.checkIsNewUserWithoutAe()) {
    //  window.EVENT.emit(OrderAction.IS_BIND_AE_MODAL, true)
    //  return;
    //}
    if (!item.is_pub) {
      message.error(intl.get('product.open_mapping_tip'));
      return;
    }

    this.mappingFromBanner = fromBanner;

    this.setState({
      showDeputySupplier: true,
      mappingProduct: item
    });

    reactGaEvent({
      category: 'Product',
      action: 'SupplierManagement'
    });
  };

  hideDeputySupplier = hasSave => {
    this.setState({
      showDeputySupplier: false
    });

    this.getStoreList(this.props.storeId);
    hasSave && this.getBannerList();
  };

  SelectClick = ''; // 下拉默认第一个
  SelectClickId = ''; // 下拉默认第一个ID
  saveShipmentWho = 1; // 保存副供应商物流 1 => option1  2 => option2
  clickLogistics = [];
  valueId = '';

  getVisibleColumns = () => {
    // 获取visible进度tr内容
    const visibleColumns = [
      {
        title: intl.get('product_edit.picture'),
        dataIndex: 'image',
        width: 100,
        render: (image, record) => {
          return <img src={replaceUrlToHttps(image)} style={{ height: 40 }} />;
        }
      },
      {
        title: intl.get('product_edit.title'),
        dataIndex: 'title'
      },
      {
        title: intl.get('order.fulfill_confirm.table_title.feedback'),
        dataIndex: 'feedback',
        width: 100,
        render: (_, record) => {
          return (
            <Icon
              type={record.visibleStatus}
              style={{
                width: 16,
                display: 'block',
                margin: '0 auto',
                fontSize: 16,
                color: `${
                  record.visibleStatus == 'close-circle-o'
                    ? '#f5222d'
                    : '#52c41a'
                }`
              }}
            />
          );
        }
      },
      {
        title: intl.get('product.possible_cause'),
        width: 140,
        dataIndex: 'visibleResult'
      }
    ];

    return visibleColumns;
  };

  // 隐藏visible弹窗
  hideVisibleModal = () => {
    const obj = this.setSelectedRows();
    this.setState({
      showVisible: false,
      visibleProgress: 1,
      checkedList: obj.ids,
      checkedVisibleList: obj.list
    });
    this.visibleAll = false;
    this.visibleFailed = false;
  };

  // 更换商店
  changeStore = value => {
    this.currentPage = 1;
    this.setState(
      {
        filterByHidden: [],
        filterByNotification: [],
        filterByAgency: [],
        filterByShopifyDelete: [],
        filterByMapping: [],
        filterByMappingShow: [],
        filterByHiddenShow: [],
        filterByNotificationShow: [],
        filterByShopifyDeleteShow: [],
        filterByAgency: [],
        filterByAgencyShow: []
      },
      () => {
        this.getProductList();
      }
    );
    // this.getBannerList();
  };

  // visible完成时checked里去掉成功的
  setSelectedRows = () => {
    let ids = [...this.state.checkedList];
    let list = Object.values(this.checkedVisibleList);
    list = list.filter(item => {
      return !this.visibleIds.includes(item.id);
    });
    ids = ids.filter(val => {
      return !this.visibleIds.includes(val);
    });
    this.visibleIds.forEach(val => {
      delete this.checkedVisibleList[val];
    });
    return { ids, list };
  };

  getQueryString = name => {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
    const hash = `?${location.hash.split('?')[1]}`;
    const r = hash.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  };

  // 展示管理商品弹窗
  showManageProductDialog = () => {
    // 交互打点
    CommonDataCollect({
      user_id: this.props.userInfo.user_id,
      sale_platform: 'shopify',
      supply_platform: 'aliexpress',
      trans_from: 'myproduct click import shopify products button',
      action: 'click_import_shopify_products_button',
      event_type: 'interactive'
    });

    if (this.hasRealStore) {
      this.setState({
        showManageProduct: true
      });
    } else {
      window.open('https://apps.shopify.com/dsers', '_blank');
    }
  };

  // 关闭管理商品弹窗
  hideManageProductDialog = hasImport => {
    this.setState({
      showManageProduct: false
    });

    if (hasImport) {
      this.getProductList();
    }
  };

  // Manage product问号提示关闭回调
  manageProductTipBack = v => {
    if (!v) {
      localStorage.setItem('hasCloseManageProductTip', 1);
    }
  };

  getProductCategory = () => {
    // 商品类别筛选dom
    let link = 'https://help.dsers.com/import-products-from-shopify/';
    if (this.currentLanguage === 'fr-FR') {
      link = 'https://help.dsers.com/import-products-from-shopify/';
    }
    return (
      <>
        <Button onClick={this.showManageProductDialog} data="dx" type="primary">
          {intl.get('product.btn_import_shopify_products')}
          {/* <Tooltip
            title={
              <span
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {intl.get('product.btn_import_shopify_products_tip')}
                <br />
                <a
                  href={link}
                  target="_blank"
                >
                  {intl.get('product.btn_import_shopify_products_tip_link')}
                </a>
              </span>
            }
            defaultVisible={
              !localStorage.getItem('hasCloseManageProductTip')
            }
            arrowPointAtCenter
            onClick={e => {
              e.stopPropagation();
            }}
            onVisibleChange={this.manageProductTipBack}
          >
            <Icon type="question-circle" />
          </Tooltip> */}
        </Button>
        {/* <Select
              >
                <Option value="0">{ intl.get("product.select_source.all") }</Option>
                <Option value="2">{ intl.get("product.select_source.unPushed") }</Option>
                <Option value="1">{ intl.get("product.select_source.pushed") }</Option>
              </Select> */}
      </>
    );
  };

  // 设置商品到shopify弹窗是否可见 隐藏
  hideSetVisible = () => {
    this.publishId = null;
    this.publishStatus = null;
    this.setState({
      showSetVisible: false
    });
  };

  // 设置商品到shopify弹窗是否可见 可见
  showSetVisibleFn = (ids, status) => {
    this.publishId = ids;
    this.publishStatus = status;
    this.setState({
      showSetVisible: true
    });
  };

  publishStatus = null;
  publishId = null;
  alsoOnline = false; // 发布到shopify还是上线

  // 更改发布到shopify还是上线
  changeAlsoOnline = e => {
    this.alsoOnline = e.target.checked;
  };

  // 全选
  changeCheckAll = e => {
    this.changeCheck(
      e.target.checked ? Object.keys(this.listMap) : [],
      Object.values(this.listMap)
    );
  };

  // 获取供应商id
  getAliId = item => {
    const supplier_default = item.supplier_default || [];
    return supplier_default[0] ? supplier_default[0] : item.platfrom_id;
  };

  splitProduct = undefined; // 要拆分的商品

  // 展示商品拆分
  showSplitProduct = prod => {
    this.splitProduct = prod;
    this.setState({
      showSplitProduct: true
    });
  };

  // 隐藏商品拆分
  hideSplitProduct = flag => {
    this.setState({
      showSplitProduct: false
    });
    flag && this.getProductList();
  };

  // 获取ae商品变化，影响到几个shopify商品
  getShopifyProdItems = item => {
    const { shopify_prods = {} } = item;
    const arr = [];

    Object.values(shopify_prods).forEach(item => {
      item.forEach(i => {
        i.productid && arr.push(i);
      });
    });
    return arr;
  };

  // 获取消息标题 子组件用
  renderNotificationTitle = type => {
    let title = '';
    switch (type) {
      case 1:
        title = 'The Ali product SKU(s) is out of stock or not available'; // 库存为零
        break;
      case 2:
        title = 'The Ali product SKU(s) is out of stock or not available'; // 商品SKU变更
        break;
      case 3:
        title = 'The Ai product is out of stock or not available'; // 商品下线
        break;
      case 4:
        title = 'The product is available once again.'; // 商品重新上线  这个不处理 2019-11-25
        break;
      case 5:
        title = 'Product price has changed'; // 价格变更
        break;
      default:
        title = '';
    }
    return title;
  };

  ProductChangeData = null;

  // 点击商品卡片上的消息通知 显示详细详情
  showNotificationDetail = ProductChangeData => {
    this.ProductChangeData = ProductChangeData;
    const type =
      ProductChangeData.type == 5 ? 'showPriceChange' : 'showSkuChange';

    // 交互打点
    CommonDataCollect({
      user_id: this.props.userInfo.user_id,
      sale_platform: 'shopify',
      supply_platform: 'aliexpress',
      trans_from: 'click product card tip',
      action: 'click_product_card_tip',
      event_type: 'interactive'
    });

    this.setState({
      [type]: true
    });

    this.setIsRead(ProductChangeData);
  };

  // 设置消息已读
  setIsRead = li => {
    this.props.dispatch({
      type: 'notification/update',
      payload: {
        data: {
          id: li.id,
          isread: true
        }
      }
    });
  };

  // 关闭 Notification stock详情弹窗
  hideSkuChange = () => {
    this.setState({
      showSkuChange: false
    });
    this.getProductList();
  };

  // 关闭 Notification price详情弹窗
  hidePriceChange = () => {
    this.setState({
      showPriceChange: false
    });
    this.getProductList();
  };

  // Notification弹窗详情里的 mapping
  showMapping = shopifyProdData => {
    const { prod } = shopifyProdData;

    const obj = {
      id: prod.id,
      image: prod.detail.image.src,
      title: prod.detail.title,
      prod
    };

    this.setState({
      showDeputySupplier: true,
      mappingProduct: obj
    });
  };

  checkChange = e => {
    const check = e?.target?.checked;

    this.setState({
      checkDelete: check
    });
  };

  // 商品加黑输入confirm
  inputHideProdConfirm = e => {
    this.setState({
      showErrorTip:
        e.target.value &&
        e.target.value.trim().toLocaleLowerCase() !==
          confirmLanguage[this.currentLanguage]
    });
    this.setState({
      hideProductConfirmDisabled:
        e.target.value.trim().toLocaleLowerCase() !==
        confirmLanguage[this.currentLanguage]
      //hideProductConfirmDisabled: e.target.value.trim().toLocaleLowerCase() !== 'confirm'
    });
  };

  batchAgenciesList = null;

  showBatchAgencies = list => {
    this.batchAgenciesList = list;
    this.setState({
      batchAgenciesDrawer: true
    });
  };

  batchAgenciesDrawerClose = () => {
    this.setState({
      batchAgenciesDrawer: false
    });
    this.getProductList();
  };

  addBlackProduct = null;

  /**
   * 点击商品加黑眼睛图标或More action批量加黑, 如果list.length === 1 要判断
   * @param {Array} list 需要加黑或取消加黑的商品数组
   */
  showEye = list => {
    this.addBlackProduct = list;
    if (list[0]?.is_black_list === 2 && list.length === 1) {
      this.showEyeConfirm();
      return;
    }
    this.setState({
      showBlackProductConfirm: true
    });
  };

  // 发送加黑请求
  showEyeConfirm = (e, i = 0) => {
    this.props.dispatch({
      type: 'productmy/blackList',
      payload: {
        data: {
          id: this.addBlackProduct[i]?.id,
          is_black_list: this.addBlackProduct[i]?.is_black_list === 1 ? 2 : 1 // 2  拉黑商品   1 恢复商品
        },
        callback: e => {
          if (e.code === 2010 && this.addBlackProduct[i]?.is_black_list === 1) {
            const {
              ae_pid,
              storeId,
              prod: { detail }
            } = this.addBlackProduct[i];
            const { user_id } = JSON.parse(localStorage.getItem('store'));
            CommonDataCollect({
              user_id: user_id,
              // 店铺ID
              store_id: storeId,
              // 店铺名称
              shop_name: '',
              // 销售平台 shopify  wix  woocommerce
              sale_platform: 'shopify',
              // 销售平台对应商品ID
              sale_platform_product_id: detail.id,
              // 销售平台对应订单ID
              sale_platform_order_id: '',
              // 供应商平台  aliexpress
              supply_platform: 'aliexpress',
              // 供应商平台商品ID
              supply_platform_product_id: ae_pid,
              // 供应商平台订单ID
              supply_platform_order_id: '',
              // 操作来源 如dsers
              trans_from: '',
              // 操作目的 如shopify
              trans_to: '',
              // 操作如 import_product
              action: 'hide_product',
              // 表名第二段 模块名称 如: product
              event_type: 'subscription_analysis'
            });
          }
          if (this.addBlackProduct.length > i + 1) {
            this.showEyeConfirm(undefined, i + 1);
          } else {
            this.hideBlackProductConfirm();
            this.getProductList();
          }
        }
      }
    });
  };

  getReplaceId = (id, e_id) => {
    const { eventId, showLoadingReplace } = this.state;
    eventId.push(e_id);

    const showLoading = {
      replaceId: id,
      eventId: e_id
    };

    this.setState({
      replaceId: id,
      eventId: eventId,
      showLoadingReplace: showLoadingReplace.push(showLoading)
    });
  };

  // 隐藏加黑弹窗
  hideBlackProductConfirm = () => {
    this.setState({
      showBlackProductConfirm: false,
      hideProductConfirmDisabled: true
    });
    this.addBlackProduct = null;
  };

  handleDataLoading = () => {
    const local = JSON.parse(localStorage.getItem('replace_event_id'));

    if (typeof local == 'string') {
      local = {};
    }

    if (local) {
      let loadingData = cloneDeep(local);
      loadingData?.map(item => {
        item.status = 'loading';
        return item;
      });

      this.setState({
        loadingData: loadingData
      });
    }
  };

  // 发送GA统计
  sendGA = e => {
    const {
      target: {
        dataset: { ga }
      }
    } = e;
    if (ga) {
      reactGaEvent({
        category: 'My Product',
        action: ga
      });
    }
  };

  // 点击more action
  handleMoreActionMenu = ({ key }) => {
    switch (key) {
      case 'delete':
        this.removeProduct(this.state.checkedList, 1);
        break;
      case 'hide':
        this.showEye(Object.values(this.checkedVisibleList));
        break;
      default:
    }
  };

  handleBannerClick = value => {
    this.props.dispatch({
      type: 'global/showOneClickMapping',
      payload: value,
      callback: d => {
        if (d.code == 2000) {
          console.log('刷新广告列表------------------');
          this.getBannerList();
        }
      }
    });
  };

  // 检测是否是从来没有link过AE的新用户

  checkIsNewUserWithoutAe = () =>
    localStorage.getItem('aliExpressAuthStatus') === 'false' &&
    localStorage.getItem('newUserWithoutAe') === 'true';

  showNewUserAeModal = () => this.setState({ isNewUserAeModalShow: true });

  closeNewUserAeModal = () => this.setState({ isNewUserAeModalShow: false });

  selectFilterProductByAgency = value => {
    console.log(value);
    this.setState({
      filterByAgency: value
    });
  };

  selectFilterProductByNotification = value => {
    console.log(value);
    this.setState({
      filterByNotification: value
    });
  };

  selectFilterProductByMapping = value => {
    this.setState({
      filterByMapping: value
    });
  };

  selectFilterProductByHidden = value => {
    console.log(value);
    this.setState({
      filterByHidden: value
    });
  };

  selectFilterProductByShopifyDelete = v => {
    console.log(v);
    this.setState({
      filterByShopifyDelete: v
    });
  };

  toFilterProduct = () => {
    this.setState({
      filterByHiddenShow: this.state.filterByHidden,
      filterByNotificationShow: this.state.filterByNotification,
      filterByShopifyDeleteShow: this.state.filterByShopifyDelete,
      filterByMappingShow: this.state.filterByMapping,
      filterByAgencyShow: this.state.filterByAgency
    });
    this.getProductList('filter');
  };

  // 打开过滤商品弹窗
  openFilterProductModal = () => {
    this.saveStatus = false;
    this.filterByHiddenCopy = this.state.filterByHidden;
    this.filterByNotificationCopy = this.state.filterByNotification;
    this.filterByAgencyCopy = this.state.filterByAgency;
    this.filterByShopifyDeleteCopy = this.state.filterByShopifyDelete;
    this.filterByMappingCopy = this.state.filterByMapping;
    this.setState({
      filterProductVisible: true
    });
  };

  handleCloseShopifyDel = v => {
    const arr = this.state.filterByShopifyDelete.filter(i => {
      return i != v;
    });
    const arr1 = this.state.filterByShopifyDeleteShow.filter(i => {
      return i != v;
    });
    this.setState(
      {
        filterByShopifyDelete: arr,
        filterByShopifyDeleteShow: arr1
      },
      () => {
        this.getProductList();
      }
    );
  };

  handleCloseyAgency = v => {
    const arr = this.state.filterByAgency.filter(i => {
      return i != v;
    });
    const arr1 = this.state.filterByAgencyShow.filter(i => {
      return i != v;
    });
    this.setState(
      {
        filterByAgency: arr,
        filterByAgencyShow: arr1
      },
      () => {
        this.getProductList();
      }
    );
  };

  handleCloseHidden = v => {
    const arr = this.state.filterByHidden.filter(i => {
      return i != v;
    });
    const arr1 = this.state.filterByHidden.filter(i => {
      return i != v;
    });
    this.setState(
      {
        filterByHidden: arr,
        filterByHiddenShow: arr1
      },
      () => {
        this.getProductList();
      }
    );
  };
  handleCloseNotification = v => {
    const arr = this.state.filterByNotification.filter(i => {
      return i != v;
    });
    const arr1 = this.state.filterByNotification.filter(i => {
      return i != v;
    });
    this.setState(
      {
        filterByNotification: arr,
        filterByNotificationShow: arr1
      },
      () => {
        this.getProductList();
      }
    );
  };

  handleCloseMapping = v => {
    const arr = this.state.filterByMapping.filter(i => {
      return i != v;
    });
    const arr1 = this.state.filterByMapping.filter(i => {
      return i != v;
    });
    this.setState(
      {
        filterByMapping: arr,
        filterByMappingShow: arr1
      },
      () => {
        this.getProductList();
      }
    );
  };

  handleUpdatePrice = () => {
    this.setState({
      updateModalVisible: true
    });
  };

  closeUpdateModal = () => {
    this.setState({
      updateModalVisible: false
    });
  };

  handleUpdatePriceStart = uniqueId => {
    localStorage.setItem('updateprice_task_mp', uniqueId);
    this.setState({
      updateReqId: uniqueId,
      updateLoading: true,
      updateModalVisible: false
    });
  };

  handleUpdatePricePendingProcess = data => {
    this.setState({
      updateLoading: true,
      updateLoadingProducts: data.running_product_id_list
    });
  };

  handleUpdatePriceFinsh = () => {
    this.setState({
      updateLoading: false,
      updateLoadingProducts: []
    });
    this.getProductList();
  };

  handleUpdatePriceError = () => {
    this.setState({
      updateLoading: false,
      updateLoadingProducts: []
    });
    this.getProductList();
  };
  clearSearchHandler = e => {
    e.stopPropagation();
    this.searchKey = '';
    this.Echo = false;
    this.setState({
      productValue: ''
    });
    this.getProductList();
  };

  getShowGuideHandler = () => {
    const plist = getProductShowData(
      this.props?.productmy?.data,
      this.props.storeId
    );
    const i = plist.findIndex(i => {
      return !i.is_del;
    });
    if (i) {
      return i;
    } else {
      return 0;
    }
  };

  refreshBounced = () => {
    this.setState({
      refreshBouncedVisible: true
    });
  };

  handleRefreshBounced = () => {
    this.setState({
      refreshBouncedVisible: false
    });
  };

  changeAeUrl = e => {
    this.setState(
      {
        aeUrl: e.target.value
      },
      () => {
        window.TMALL_USER
          ? this.checkAeUrl(this.state.aeUrl)
          : this.checkAeUrl2(this.state.aeUrl);
      }
    );
  };

  checkAeUrl = url => {
    const { replaceSelectValue } = this.state;
    console.log(
      aeUrlMatchReg.test(url),
      isUrl(url),
      tmallUrlMatchReg.test(url),
      '0========000'
    );
    if (url) {
      if (aeUrlMatchReg.test(url) || tmallUrlMatchReg.test(url)) {
        if (
          (replaceSelectValue === 2 && !aeUrlMatchReg.test(url)) ||
          (replaceSelectValue === 4 && !tmallUrlMatchReg.test(url))
        ) {
          this.setState({
            supplierUrlError: true
          });
          return;
        }
      } else {
        this.setState({
          aeUrlError: true
        });
        return;
      }

      this.setState(
        {
          aeUrlError: false,
          supplierUrlError: false,
          changeAe: true
        },
        () => {
          this.getAeDetails(url);
        }
      );
    }
  };

  checkAeUrl2 = url => {
    let pattern = /(\d+)\.html/;
    if (!pattern.test(url)) {
      this.setState({
        aeUrlError: true
      });
      return;
    }

    this.setState(
      {
        aeUrlError: false,
        changeAe: true
      },
      () => {
        this.getAeDetails(url);
      }
    );
  };

  showLoading = () => {
    this.setState({
      aeLoading: true
    });
  };

  /**
   * 请求ae详情
   * @param {} changeStatus
   */
  getAeDetails = url => {
    const { replaceSelectValue } = this.state;
    this.showLoading();
    this.aeUrl = url;
    this.props.dispatch({
      type:
        replaceSelectValue === 4
          ? 'productmy/tmProductDetail'
          : 'productmy/aeProductDetail',
      payload: {
        data: {
          url: url,
          product_id: getSupplierId(replaceSelectValue, url),
          supplier_type: replaceSelectValue
        },
        callback: d => {
          if (d.code == 2000) {
            this.setState({
              aeLoading: false,
              aeDetails: d.data
            });
            this.aeDetailsImg = d.data.image_u_r_ls.split(';')[0];
          } else {
            this.setState({
              changeAe: false
            });
          }
        }
      }
    });
  };

  hideDetail = () => {
    this.setState({
      replaceDrawer: false
    });
  };

  hideReplaceModal = () => {
    this.setState(
      {
        replaceStatus1: false,
        aeUrl: '',
        aeUrlError: false,
        changeAe: false,
        supplierUrlError: false,
        replaceSelectValue: 2
      },
      () => {
        this.deleteReplaceProduct();
      }
    );
  };

  productOpenOnAe = productDate => {
    if (productDate.product_id) {
      window.open(
        getSupplierUrl(
          productDate.supplier_type,
          productDate.product_id_str || productDate.product_id
        )
      );
    }
  };
  deleteReplaceProduct = () => {
    this.setState({
      changeAe: false,
      aeUrl: ''
    });
  };

  // 在shopify打开商品详情
  productOpenOnShopify = () => {
    const {
      userInfo: { stores }
    } = this.props;

    const {
      need_product: {
        prod: {
          detail: { id },
          store_id
        },
        storeId
      }
    } = this.state;

    const currenctStoreId = store_id || storeId;

    const productStore = stores.find(store => store.id === currenctStoreId);

    if (productStore && id) {
      window.open(
        `https://${productStore.shop_name}.myshopify.com/admin/products/${id}`,
        '_blank'
      );
    }
  };

  openAeImport = () => {
    const { replaceSelectValue } = this.state;

    if (replaceSelectValue === 4) {
      CommonDataCollect({
        action: 'myproduct_replace_tmall',
        event_type: 'my_product_tmall'
      });
    }

    this.props.dispatch({
      type: 'productmy/getAeData',
      payload: {
        data: {
          replace_id: this.aeUrl,
          product_id: getSupplierId(replaceSelectValue, this.aeUrl),
          supplier_type: replaceSelectValue
        },
        callback: d => {
          console.log(d, 'd-=-=');
          if ((d.code = 2000)) {
            d.data.detail.variants.forEach(i => {
              i.aeCost = i.price;
            });
            this.setState(
              {
                newProductDetails: d.data,
                replaceDrawer: true,
                replaceStatus1: false
              },
              () => {
                this.deleteReplaceProduct();
              }
            );
          }
        }
      }
    });
  };

  getReplaceId = id => {
    this.props.getReplaceId(id);
  };

  getNewAgencyManagementData = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'global/getAgencyListFromMerchant' });
    this.handleRefreshBounced();
  };

  replaceChangeSelectHandler = v => {
    const { aeUrl, replaceSelectValue } = this.state;
    this.setState(
      {
        replaceSelectValue: v
      },
      () => {
        this.checkAeUrl(aeUrl);
      }
    );
  };
  render() {
    const {
      productmy = {},
      updateProductLoading,
      blackListLoading,
      listLoading,
      removeProductLoading,
      storeId,
      lang
    } = this.props;

    const {
      data = {},
      updateStatus,
      removeStatus,
      changedStatus,
      addStatus
    } = productmy;

    const {
      showDetail,
      checkedList = [],
      bannerList = [],
      showVisible,
      visibleProgress,
      checkedVisibleList,
      productValue,
      isSync,
      showManageProduct,
      showDeputySupplier,
      mappingProduct,
      productKeys,
      showSetVisible,
      showSplitProduct,
      showPriceChange,
      showSkuChange,
      hideProductConfirmDisabled,
      showBlackProductConfirm,
      showRemoveProductConfirm,
      isAeProductUrl,
      isNewUserAeModalShow,
      supplyBannerData,
      showSupplyBanner,
      supplyBannerExtraData,
      supplyBannerLoading,
      removeVisible,
      ids,
      isMany,
      replaceId,
      replaceStatus,
      checkDelete,
      filterProductVisible,
      productNums,
      updateModalVisible,
      ifUseUpdate,
      updateReqId,
      updateLoading,
      updateLoadingProducts,
      guideIndex,
      refreshBouncedVisible,
      selectData,
      changeAe,
      aeUrl,
      aeUrlError,
      aeLoading,
      aeDetails,
      replaceDrawer,
      newProductDetails,
      replaceStatus1,
      need_product,
      supplierUrlError,
      filterByAgencyShow,
      filterByMappingShow,
      filterByHiddenShow,
      filterByNotificationShow,
      filterByShopifyDeleteShow,

      batchAgenciesDrawer
    } = this.state;

    const CAINIAO_USER = localStorage.getItem('CAINIAO_USER');

    const optionsNotification = [
      {
        id: '1',
        label: intl.getHTML('product.filterByVariants'),
        value: 'variants_out_of_stock',
        disabled:
          !!this.state.filterByHidden.length ||
          this.state.productKeys === '1' ||
          !!this.state.filterByShopifyDelete.length
      },
      {
        id: '2',
        label: intl.getHTML('product.filterBySku'),
        value: 'sku_changed',
        disabled:
          !!this.state.filterByHidden.length ||
          this.state.productKeys === '1' ||
          !!this.state.filterByShopifyDelete.length
      },
      {
        id: '3',
        label: intl.getHTML('product.productOutOfStock'),
        value: 'product_out_of_stock',
        disabled:
          !!this.state.filterByHidden.length ||
          this.state.productKeys === '1' ||
          !!this.state.filterByShopifyDelete.length
      },
      {
        id: '4',
        label: intl.getHTML('product.productNotFound'),
        value: 'product_not_found',
        disabled:
          !!this.state.filterByHidden.length ||
          this.state.productKeys === '1' ||
          !!this.state.filterByShopifyDelete.length
      }
    ];

    const optionsMapping = [
      {
        id: '1',
        label: intl.getHTML('product.basic_mapping'),
        value: 'basic',
        disabled: false
      },
      {
        id: '2',
        label: intl.getHTML('product.standard_mapping'),
        value: 'standard',
        disabled: false
      },
      {
        id: '3',
        label: intl.getHTML('product.advance_mapping'),
        value: 'advanced',
        disabled: false
      },
      {
        id: '4',
        label: intl.getHTML('product.bogo_mapping'),
        value: 'bogo',
        disabled: false
      },
      {
        id: '5',
        label: intl.getHTML('product.bundle_mapping'),
        value: 'bundle',
        disabled: false
      }
    ];

    const optionsHidden = [
      {
        id: '1',
        label: intl.getHTML('product.filterByHid'),
        value: true,
        disabled: !!this.state.filterByNotification.length
      }
    ];

    const optionsShopifyDelete = [
      {
        id: '1',
        label: intl.getHTML('product.filterByShopifyDelete'),
        value: true,
        disabled: !!this.state.filterByNotification.length
      }
    ];

    const filterAgency = [];

    selectData.forEach(item => {
      const obj = {
        id: item.id,
        label: item.agency_name,
        value: item.id,
        disabled: false
      };
      filterAgency.push(obj);
    });

    let filterDrawerOptions = [
      {
        key: '1',
        title: intl.getHTML('product.filterByNotification'),
        filterComponent: (
          <>
            {
              <Checkbox.Group
                className={styles3.fliterCheckoutGroup}
                options={optionsNotification}
                value={this.state.filterByNotification}
                onChange={this.selectFilterProductByNotification}
              />
            }
          </>
        )
      },
      {
        key: '2',
        title: intl.getHTML('product.filterByHidden'),
        filterComponent: (
          <>
            {
              <>
                <Checkbox.Group
                  className={styles3.fliterCheckoutGroup}
                  options={optionsHidden}
                  value={this.state.filterByHidden}
                  onChange={this.selectFilterProductByHidden}
                />
                <div style={{ marginTop: '10px' }}></div>
                <Checkbox.Group
                  className={styles3.fliterCheckoutGroup}
                  options={optionsShopifyDelete}
                  value={this.state.filterByShopifyDelete}
                  onChange={this.selectFilterProductByShopifyDelete}
                />
              </>
            }
          </>
        )
      },
      {
        key: '3',
        title: intl.getHTML('product.filterByMapping'),
        filterComponent: (
          <>
            {
              <>
                <Checkbox.Group
                  className={styles3.fliterCheckoutGroup}
                  options={
                    this.state.productKeys === '4'
                      ? optionsMapping.filter(i => {
                          return i.id === '1' || i.id === '2';
                        })
                      : optionsMapping
                  }
                  value={this.state.filterByMapping}
                  onChange={this.selectFilterProductByMapping}
                />
              </>
            }
          </>
        )
      },
      {
        key: '4',
        title: intl.get('setting.agency.filterByAgency'),
        filterComponent: (
          <>
            {filterAgency.length ? (
              <>
                <Checkbox.Group
                  className={styles3.fliterCheckoutGroup}
                  options={filterAgency}
                  value={this.state.filterByAgency}
                  onChange={this.selectFilterProductByAgency}
                />
              </>
            ) : (
              <>
                <div className={styles.agencyHint}>
                  {intl.get('setting.agency.filterAgencyPlease')}{' '}
                  <a
                    href="/app/settings?id=14"
                    target="_blank"
                    onClick={this.refreshBounced}
                  >
                    {intl.get('setting.agency.toSetting')}
                  </a>{' '}
                  {intl.get('setting.agency.filterAgencyAddInformation')}
                </div>
              </>
            )}
          </>
        )
      }
    ];

    switch (this.state.productKeys) {
      case '1':
        filterDrawerOptions = filterDrawerOptions.filter(
          i => !['1', '4'].includes(i.key)
        );
        break;
      case '2':
        filterDrawerOptions = filterDrawerOptions.filter(
          i => !['4'].includes(i.key)
        );
        break;
      case '4':
        filterDrawerOptions = filterDrawerOptions.filter(
          i => !['4'].includes(i.key)
        );
        break;
      case '5':
        filterDrawerOptions = filterDrawerOptions.filter(
          i => !['1', '3'].includes(i.key)
        );
        break;
      default:
        break;
    }

    // 获取筛选options
    const getfilterDrawerOptions = () => {
      const tabtypes = {
        '1': filterDrawerOptions.filter(i => i.key !== '1'),
        '2': filterDrawerOptions,
        '4': filterDrawerOptions,
        '5': filterDrawerOptions,
        '999': filterDrawerOptions
      };
      return tabtypes[this.state.productKeys];
    };
    // 避免 banner 加载造成抖动
    if (supplyBannerLoading) {
      return (
        <Spin size="large" style={{ width: '100%', margin: '180px auto' }} />
      );
    }

    let { list = [] } = data || {};
    let renderList = [];

    const total = data.pages;

    list = getProductShowData(data, storeId);

    if (this.currentPage === 1) {
      renderList = bannerList.concat(getProductShowData(data, storeId));
    }
    const fbdSupply = data.supply || {};

    // console.log( list, checkedList, 888 )

    this.productKeys = localStorage.getItem('produstKey');
    this.initListMap(list);
    let firstId = '';
    if (this.currentPage === 1) {
      firstId = list[0]?.id || '';
    }

    const moreActionMenu = (
      <Menu onClick={this.handleMoreActionMenu}>
        <Menu.Item key="delete">
          {intl.get('product.delete_selected_products')}
        </Menu.Item>
        <Menu.Item
          key="hide"
          disabled={Object.values(this.checkedVisibleList).length === 0}
        >
          {intl.get('product.hide_selected_products')}
        </Menu.Item>
      </Menu>
    );

    const is_searched =
      filterByHiddenShow.length ||
      filterByNotificationShow.length ||
      filterByShopifyDeleteShow.length ||
      filterByMappingShow.length ||
      filterByAgencyShow.length ||
      this.searchKey;

    const orderNoData =
      isSync || is_searched ? (
        <div className={styles.noDataSync}>
          <p>
            <img src={iconShop} />
          </p>
          <dl>
            <dt>
              <p>
                {is_searched
                  ? intl.getHTML('import_list.search_no_product_tip')
                  : intl.get('product.no_data.is_sync')}
              </p>
            </dt>
          </dl>
        </div>
      ) : (
        <div className={styles.noData}>
          <div className={styles.description}>
            <span className={styles.title}>
              {intl.getHTML('import_list.no_product_tip')}
            </span>
            <span className={styles.text}>
              {intl.getHTML('product.no_data.no_product_tip_1')}
            </span>
            <span className={styles.text}>
              {intl.getHTML('product.no_data.no_product_tip_2')}
            </span>
          </div>
          <img src={push_guide} alt="" />
        </div>
      );

    const cardList = (
      <Checkbox.Group
        value={checkedList}
        style={{ width: '100%' }}
        onChange={e => {
          this.changeCheck(e, list);
        }}
      >
        <List
          rowKey="id"
          loading={listLoading}
          dataSource={list}
          className={styles3.myListGrid}
          locale={{ emptyText: orderNoData }}
          renderItem={(item, index) => {
            const local = JSON.parse(localStorage.getItem('replace_event_id'));
            return (
              <Spin
                spinning={
                  (local?.filter(items => items.replaceId == item.id).length > 0
                    ? true
                    : false) ||
                  (updateLoading && updateLoadingProducts?.includes(item.id))
                }
              >
                <Product
                  firstId={firstId}
                  storeId={storeId}
                  product={item}
                  push={this.showSetVisibleFn}
                  removeProduct={this.removeProduct}
                  showEye={this.showEye}
                  showSupplier={this.showDeputySupplier}
                  showDetail={this.showDetail}
                  copyLink={this.copyLink}
                  showNotificationDetail={this.showNotificationDetail}
                  index={index}
                  guideIndex={guideIndex}
                  getReplaceTime={this.getReplaceTime}
                  getReplaceId={this.getReplaceId}
                  hideSupplier={this.hideDeputySupplier}
                  openReplace={v =>
                    this.setState({
                      replaceStatus1: true,
                      need_product: v
                    })
                  }
                />
              </Spin>
              // <>
              //   {item.isBanner ? (
              //     <BannerCard
              //       data={item}
              //       type={2}
              //       handleBannerClick={this.handleBannerClick}
              //     />
              //   ) : (
              //     <Product
              //       firstId={firstId}
              //       storeId={storeId}
              //       product={item}
              //       push={this.showSetVisibleFn}
              //       removeProduct={this.removeProduct}
              //       showEye={this.showEye}
              //       showSupplier={this.showDeputySupplier}
              //       showDetail={this.showDetail}
              //       copyLink={this.copyLink}
              //       showNotificationDetail={this.showNotificationDetail}
              //     />
              //   )}
              // </>
            );
          }}
        />
      </Checkbox.Group>
    );

    if (updateStatus || removeStatus || changedStatus || addStatus) {
      if (addStatus) {
        this.searchKey = '';
      }
      this.getProductList();
    }

    return (
      <>
        {/* 商品详情抽屉 */}
        <Drawer
          style={{ borderRadius: 12 }}
          placement="right"
          className="souti"
          title={intl.get('import_list.check_detail')}
          closable={true}
          onClose={() => {
            this.setState({
              showDetail: false
            });
          }}
          visible={showDetail}
          width={1008}
          destroyOnClose={true}
        >
          <MyProdcutEdit
            storeId={storeId}
            pid={this.currentProdcut.id}
            producteDetail={this.currentProdcut}
            onHide={this.hideDetail}
            onSubmit={this.updateMyProduct}
            updateProductLoading={updateProductLoading}
            removeProduct={this.removeProduct}
            copyLink={this.copyLink}
          />
        </Drawer>
        <>
          <div className={styles.productBox} key="min">
            <div style={{ marginTop: '24px' }}></div>

            {/* <OldUiOutBanner dispatch={this.props.dispatch} /> */}
            <ProductPoolBanner showBtn dispatch={this.props.dispatch} />
            {/* <AdBanner lang={lang} style={{ marginBottom: 20 }} /> */}
            <div className={styles.title_function}>
              <h2 className={styles.wqoew}>{intl.get('product.myProducts')}</h2>
              <div style={{ width: '80%' }} className={styles.productFunctions}>
                <div className={styles.functions}>
                  <SearchInput
                    value={productValue}
                    onChange={this.changeSearchKey}
                    isAeProductUrl={isAeProductUrl}
                    onSubmit={this.addProduct}
                    getPlatform={v => {
                      this.setState({
                        selectValue: v
                      });
                    }}
                    openInputBtnText={intl.get(
                      'supplier_optimizer.search_product'
                    )}
                    placeholder={intl.get('import_list.search_placeholder')}
                    selectValue={this.state.selectValue}
                    hiddenSelect={true}
                    allowClear={true}
                    autoFocus={true}
                    checkUrl
                  ></SearchInput>
                  {productKeys !== '999' ? (
                    <>
                      <div className="xt-border"></div>
                      <div className={styles.search}>
                        <Button onClick={this.openFilterProductModal}>
                          <i class="material-icons notranslate">filter_alt</i>{' '}
                          {intl.get('import_list.filter')}
                        </Button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={styles.productInp} style={{ marginBottom: 16 }}>
              {supplyBannerData.length > 0 && showSupplyBanner ? (
                <div className={`${styles2.supplyBanner} ${styles2.products}`}>
                  <span className={styles2.close}>
                    <Icon type="close" onClick={this.handleBannerClose} />
                  </span>
                  <h1>Recommendation from DSers Mass Supplier</h1>
                  <List
                    rowKey="id"
                    // loading={listLoading}
                    grid={{ gutter: 24, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
                    dataSource={supplyBannerData}
                    locale={{ emptyText: orderNoData }}
                    renderItem={(item, index) => (
                      <Product
                        storeId={storeId}
                        product={item}
                        push={this.showSetVisibleFn}
                        removeProduct={this.removeProduct}
                        showEye={this.showEye}
                        showSupplier={this.showDeputySupplier}
                        showDetail={this.showDetail}
                        copyLink={this.copyLink}
                        showNotificationDetail={this.showNotificationDetail}
                        onBannerItemClick={this.handleBannerItemClick}
                        supplyBannerExtraData={supplyBannerExtraData}
                        index={index}
                        guideIndex={guideIndex}
                      />
                    )}
                  />
                </div>
              ) : null}
              <div className={styles.aeProduct_add}>
                {
                  <div className={styles.filterOptionContainer}>
                    {optionsNotification.map(item => {
                      if (
                        this.state.filterByNotificationShow.includes(item.value)
                      ) {
                        return (
                          <span className={styles.filterOption}>
                            <span onClick={this.openFilterProductModal}>
                              {item.label}
                            </span>
                            <img
                              src={closeFilterIcon}
                              onClick={() =>
                                this.handleCloseNotification(item.value)
                              }
                              alt=""
                            />
                          </span>
                        );
                      }
                    })}
                    {optionsHidden.map(item => {
                      if (this.state.filterByHiddenShow.includes(item.value)) {
                        return (
                          <span className={styles.filterOption}>
                            <span onClick={this.openFilterProductModal}>
                              {item.label}
                            </span>
                            <img
                              src={closeFilterIcon}
                              onClick={() => this.handleCloseHidden(item.value)}
                              alt=""
                            />
                          </span>
                        );
                      }
                    })}
                    {optionsShopifyDelete.map(item => {
                      if (
                        this.state.filterByShopifyDeleteShow.includes(
                          item.value
                        )
                      ) {
                        return (
                          <span className={styles.filterOption}>
                            <span onClick={this.openFilterProductModal}>
                              {item.label}
                            </span>
                            <img
                              src={closeFilterIcon}
                              onClick={() =>
                                this.handleCloseShopifyDel(item.value)
                              }
                              alt=""
                            />
                          </span>
                        );
                      }
                    })}
                    {optionsMapping.map(item => {
                      if (this.state.filterByMappingShow.includes(item.value)) {
                        return (
                          <span className={styles.filterOption}>
                            <span onClick={this.openFilterProductModal}>
                              {item.label}
                            </span>
                            <img
                              src={closeFilterIcon}
                              onClick={() =>
                                this.handleCloseMapping(item.value)
                              }
                              alt=""
                            />
                          </span>
                        );
                      }
                    })}

                    {filterAgency.map(item => {
                      if (this.state.filterByAgencyShow.includes(item.value)) {
                        return (
                          <span className={styles.filterOption}>
                            <span>{item.label}</span>
                            <img
                              src={closeFilterIcon}
                              onClick={() =>
                                this.handleCloseyAgency(item.value)
                              }
                              alt=""
                            />
                          </span>
                        );
                      }
                    })}
                  </div>
                }
                {/* <div className={styles3.importListSearch}>
                  <Input
                    placeholder={intl.get('product.search_placeholder')}
                    value={productValue}
                    onChange={this.changeSearchKey}
                    onPressEnter={() => {
                      this.addProduct(this.state.productValue);
                    }}
                    allowClear
                    size="large"
                  />
                  <div
                    className={styles3.ok}
                    onClick={() => {
                      this.addProduct(this.state.productValue);
                    }}
                  >
                    {intl.get('public.ok')}
                  </div>
                </div>
                <div
                  style={{ color: 'red', position: 'relative', top: '10px' }}
                >
                  {isAeProductUrl
                    ? intl.get('product.search_notice_for_migrate')
                    : ''}
                  &nbsp;
                </div> */}
                <ProductErrorTip></ProductErrorTip>
              </div>
              <div className={styles.aeProduct_Tabs}>
                <div className="myProductTab">
                  <Tabs
                    tabBarExtraContent={this.getProductCategory()}
                    activeKey={productKeys}
                    onChange={this.changeTabs}
                  >
                    <TabPane
                      tab={intl.getHTML('product.tab_all', {
                        all_num: productNums.all_num
                      })}
                      key="999"
                    />
                    <TabPane
                      tab={intl.getHTML('product.tab_aliexpress', {
                        ae_num: productNums.ae_num
                      })}
                      key="2"
                    />
                    {/*  */}
                    {window.CAINIAO_USER || CAINIAO_USER ? (
                      <TabPane
                        tab={intl.getHTML('setting.agency.Agencies', {
                          ae_num: productNums.agency_count
                        })}
                        key="5"
                      />
                    ) : null}
                    {window.TMALL_USER ? (
                      <TabPane
                        tab={intl.getHTML('product.tab_tmall', {
                          tm_num: productNums.tm_num
                        })}
                        key="4"
                      />
                    ) : null}
                    <TabPane
                      tab={intl.getHTML('product.tab_other', {
                        map_num: productNums.unmap_num
                      })}
                      key="1"
                    />
                  </Tabs>
                </div>
              </div>
            </div>
            {this.state.loadingData?.map(item => {
              switch (item.status) {
                case 'loading':
                  return (
                    <div className={styles.replaceLoading}>
                      <Icon type="loading" />
                      {intl.get('product.loading')}
                    </div>
                  );
                case 'error':
                  return (
                    <div className={styles.replaceError}>
                      <i class="material-icons">error</i>
                      {intl.get('product.error')}
                    </div>
                  );
                case 'success':
                  return (
                    <div className={styles.replaceSuccess}>
                      <i class="material-icons">check_circle</i>
                      {intl.get('product.success')}
                    </div>
                  );
              }
            })}
            <UpdatepriceNotice_mp
              reqId={updateReqId}
              onFinish={this.handleUpdatePriceFinsh}
              onPending={this.handleUpdatePricePendingProcess}
              onError={this.handleUpdatePriceError}
            />
            <div style={{ position: 'relative' }} className="selection">
              <Checkbox
                onChange={this.changeCheckAll}
                indeterminate={
                  checkedList.length === 0
                    ? false
                    : checkedList.length !== list.length
                }
                checked={
                  checkedList.length === list.length && checkedList.length != 0
                }
              >
                {ifUseUpdate ? (
                  <div className={styles.upDatePrice}>
                    <Button
                      type="primary"
                      loading={updateLoading}
                      disabled={checkedList.length == 0}
                      onClick={this.handleUpdatePrice}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      {intl.get('product.update')}{' '}
                      <div style={{ marginLeft: '3px' }}>
                        <Tooltip
                          title={intl.get('product.updatePrice')}
                          placement="topRight"
                        >
                          <Icon
                            style={{ verticalAlign: 'middle' }}
                            type="question-circle"
                          />
                        </Tooltip>
                      </div>
                    </Button>
                  </div>
                ) : (
                  <p className="selectAll">{intl.get('product.selectAll')}</p>
                )}
              </Checkbox>
              {/* <Dropdown
                  overlay={moreActionMenu}
                  disabled={checkedList.length === 0}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  <a
                    className={styles.moreActionDropdown}
                    onClick={e => e.preventDefault()}
                  >
                    {intl.get('mapping.more_action')} <Icon type="down" />
                  </a>
                </Dropdown> */}
              <div className="right_abr">
                {window.CAINIAO_USER || CAINIAO_USER ? (
                  <>
                    <Button
                      disabled={
                        Object.values(this.checkedVisibleList).length === 0
                      }
                      onClick={() => {
                        this.showBatchAgencies(
                          Object.values(this.checkedVisibleList)
                        );
                      }}
                    >
                      <img
                        src={
                          Object.values(this.checkedVisibleList).length
                            ? ormB
                            : orm
                        }
                        className={styles.orgIconStyle}
                      />{' '}
                      Products Mapping
                    </Button>
                    <div className="xt-border"></div>
                  </>
                ) : null}
                <Button
                  disabled={Object.values(this.checkedVisibleList).length === 0}
                  onClick={() => {
                    this.showEye(Object.values(this.checkedVisibleList));
                  }}
                >
                  <i class="material-icons">visibility_off</i>{' '}
                  {intl.get('product.hide')}
                </Button>
                <div className="xt-border"></div>
                <Button
                  disabled={checkedList.length === 0}
                  onClick={() => {
                    this.removeProduct(this.state.checkedList, 1);
                  }}
                >
                  <i class="material-icons">delete</i> {intl.get('product.del')}
                </Button>
              </div>
              <div></div>
            </div>
            <div className={styles3.myproducts}>{cardList}</div>
            <div className={styles.coverCardList}>
              <CardPagination
                total={total || 0}
                size={this.pageSize}
                onChange={this.changePager}
                current={this.currentPage}
                visible={total}
              />
            </div>
          </div>
        </>
        {/* 商品管理 */}
        {showManageProduct ? (
          <ManageProduct
            showModal={showManageProduct}
            hideModal={this.hideManageProductDialog}
            storeList={this.storeData}
            storeId={storeId}
          />
        ) : null}
        {/* Mapping */}
        <Mapping
          visible={showDeputySupplier}
          originProductData={mappingProduct}
          hideSupplier={this.hideDeputySupplier}
          fbdSupply={fbdSupply}
          getReplaceId={this.getReplaceId}
          getReplaceTime={this.getReplaceTime}
        />
        {/* 商品拆分 */}
        <SplitProduct
          prod={this.splitProduct}
          visible={showSplitProduct}
          hideSplitProduct={this.hideSplitProduct}
        />
        {/* 删除商品 */}
        {/* intl.get('product.delete_tip') */}
        <Modal
          title={intl.get('import_list.delete_product')}
          visible={removeVisible}
          onCancel={() => {
            this.setState({
              removeVisible: false,
              ids: [],
              isMany: ''
            });
          }}
          maskClosable={true}
          width={560}
          footer={
            <Button
              data="dx"
              type="primary"
              onClick={() => {
                this.queren();
              }}
            >
              {intl.get('public.confirm')}
            </Button>
          }
        >
          Deleted products CANNOT BE RECOVERED and will be DELETED FROM YOUR
          SHOPIFY STORE. Do you want to continue?
        </Modal>
        {/* 未知 */}
        {showVisible ? (
          <Modal
            title={`${
              visibleProgress
                ? intl.get('product.visible_progress_title1')
                : intl.get('product.visible_progress_title2')
            }`}
            visible={showVisible}
            width={1100}
            maskClosable={false}
            footer={null}
            onCancel={this.hideVisibleModal}
          >
            <Table
              columns={this.getVisibleColumns()}
              dataSource={checkedVisibleList}
              pagination={false}
              rowKey="id"
            />
            {this.visibleAll && this.visibleFailed ? (
              <Progress
                percent={visibleProgress}
                status="exception"
                style={{ marginTop: 20 }}
              />
            ) : (
              <Progress percent={visibleProgress} style={{ marginTop: 20 }} />
            )}
          </Modal>
        ) : null}
        {showSetVisible ? (
          <Modal
            title={intl.get('product.product.push_to_shopify')}
            className={styles.setVisableModal}
            visible={showSetVisible}
            onCancel={this.hideSetVisible}
            footer={
              <>
                <Checkbox onChange={this.changeAlsoOnline}>
                  {intl.get('product.show_set_visible.tip')}
                </Checkbox>
                <Button type="default" onClick={this.hideSetVisible}>
                  {intl.get('public.cancel')}
                </Button>
                <Button type="primary" onClick={this.subPublishToShopify}>
                  {intl.get('public.yes')}
                </Button>
              </>
            }
            maskClosable={false}
          >
            {intl.get('product.show_set_visible.description')}
          </Modal>
        ) : null}
        {showBlackProductConfirm ? (
          <Modal
            title={'Hide Product'}
            className={styles.blackProductConfirmModal}
            visible={showBlackProductConfirm}
            footer={
              <div className={styles.btnsHidden}>
                {/* <Button data="bk" onClick={this.hideBlackProductConfirm}>
                  {intl.get('public.cancel')}
                </Button> */}
                <Button
                  data="dx"
                  type="primary"
                  loading={blackListLoading}
                  disabled={hideProductConfirmDisabled}
                  onClick={this.showEyeConfirm}
                >
                  {intl.get('public.newconfirm')}
                </Button>
              </div>
            }
            closable={true}
            onCancel={() => {
              this.setState({
                showBlackProductConfirm: false,
                showErrorTip: false
              });
            }}
            width={560}
          >
            <div className={styles.wrap}>
              {/* <div className={styles.icon}>
                <Icon type="question-circle" />
              </div> */}
              <div className={styles.content}>
                <h5 className={styles.dswqf}>
                  {intl.get('product.hide_prod_tip')}
                </h5>
                <div>
                  <Input
                    placeholder={intl.get('product.confirm_prod_tip')}
                    onChange={this.inputHideProdConfirm}
                  />
                  {/* <Input placeholder='CONFIRM' onChange={this.inputHideProdConfirm} /> */}
                </div>
                {this.state.showErrorTip ? (
                  <div style={{ color: 'red' }}>
                    {intl.getHTML('product.confirm_prod_tip')}
                  </div>
                ) : null}
              </div>
            </div>
          </Modal>
        ) : null}
        {showRemoveProductConfirm ? (
          <Modal
            title={intl.get('import_list.delete_product')}
            className={styles.blackProductConfirmModal}
            visible={showRemoveProductConfirm}
            footer={null}
            maskClosable
            onCancel={this.hideRemoveProductConfirm}
          >
            <div className={styles.wrap}>
              <div className={styles.content}>
                <h5 className={styles.dswqf}>
                  {checkDelete
                    ? intl.getHTML('product.introduce_con2')
                    : intl.getHTML('product.introduce_con1')}
                </h5>
                <div>
                  <Checkbox
                    className={styles.checkbox}
                    style={{ marginBottom: '24px' }}
                    onChange={this.checkChange}
                    checked={checkDelete}
                  >
                    {intl.get('product.introduce_checkbox')}
                  </Checkbox>
                </div>
                <div>
                  <Input
                    className={styles.deleteConfirmInput}
                    placeholder={intl.get('product.input_confirm_tip')}
                    onChange={this.inputDeleteConfirm}
                  />
                  {/* <Input placeholder='CONFIRM' onChange={this.inputDeleteConfirm} /> */}
                </div>
                {this.state.showErrorTip ? (
                  <div style={{ color: 'red' }}>
                    {intl.getHTML('product.input_confirm_tip')}
                  </div>
                ) : null}
                <div className={styles.btns}>
                  <Button
                    data="dx"
                    type="primary"
                    loading={removeProductLoading}
                    onClick={this.remove}
                  >
                    {intl.getHTML('product.introduce_ok')}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
        {filterProductVisible ? (
          <FilterDrawer
            title={intl.get('import_list.filter_product')}
            width={395}
            placement="right"
            defaultActiveKey={['1', '2', '3', '4']}
            options={filterDrawerOptions}
            visible={filterProductVisible}
            onClose={() => {
              if (!this.saveStatus) {
                this.setState({
                  filterByHidden: this.filterByHiddenCopy,
                  filterByNotification: this.filterByNotificationCopy,
                  filterByAgency: this.filterByAgencyCopy,
                  filterByShopifyDelete: this.filterByShopifyDeleteCopy,
                  filterByMapping: this.filterByMappingCopy
                });
              }
              this.setState({
                filterProductVisible: false
              });
            }}
            onCancel={() => {
              this.setState(
                {
                  filterByHidden: [],
                  filterByNotification: [],
                  filterByShopifyDelete: [],
                  filterByAgency: [],
                  filterByAgencyShow: [],
                  filterByMapping: [],
                  filterByMappingShow: [],
                  filterByHiddenShow: [],
                  filterByNotificationShow: [],
                  filterByShopifyDeleteShow: []
                },
                () => {
                  this.getProductList();
                }
              );
            }}
            onConfirm={this.toFilterProduct}
          ></FilterDrawer>
        ) : null}
        <ErrorCashbackCode />
        <AEDataAuthorization />
        <DSersSpBanner dispatch={this.props.dispatch} />
        {/* <CompletePassWord /> */}
        <NewUserWithoutAeModal
          visible={isNewUserAeModalShow}
          closeModal={this.closeNewUserAeModal}
        />
        {/* notification 价格变化详情 */}
        <PriceChange
          notificationItem={this.ProductChangeData}
          visible={showPriceChange}
          handleCancel={this.hidePriceChange}
          renderNotificationTitle={this.renderNotificationTitle}
          getShopifyProdItems={this.getShopifyProdItems}
        />
        {/* notification 库存变化详情 */}
        <SkuChange
          notificationItem={this.ProductChangeData}
          visible={showSkuChange}
          handleCancel={this.hideSkuChange}
          renderNotificationTitle={this.renderNotificationTitle}
          getShopifyProdItems={this.getShopifyProdItems}
          showMapping={this.showMapping}
        />
        <RefreshBounced
          visible={refreshBouncedVisible}
          handleRefresh={this.handleRefreshBounced}
          buttonEvent={this.getNewAgencyManagementData}
        />
        {/* UpdatePrice 弹层 */}
        {updateModalVisible ? (
          <UpdatePrice
            type="myproduct"
            visible={updateModalVisible}
            handleClose={this.closeUpdateModal}
            selectProduct={checkedList}
            onStart={this.handleUpdatePriceStart}
            storeid={this.props.storeId}
          ></UpdatePrice>
        ) : null}
        {replaceStatus1 ? (
          <Modal
            title={intl.get('mapping.replace_product.btn_title')}
            visible={true}
            destroyOnClose={true}
            footer={null}
            width={868}
            className={styles.replaceModal}
            closable={false}
            onCancel={this.hideReplaceModal}
          >
            <div
              className={styles.modalTip}
              dangerouslySetInnerHTML={{
                __html: intl.get('mapping.replace_product.modal_tip')
              }}
            />
            <div className={styles.replaceCon}>
              <div className={styles.replaceShopify}>
                <div className={styles.replaceTitle}>
                  {intl.get('mapping.replace_product.shopify')}
                </div>
                <div className={styles.container}>
                  <img src={need_product.image} />
                  <div className={styles.aeDetails}>
                    <a
                      role="button"
                      tab-index="0"
                      onClick={() => {
                        this.productOpenOnShopify(need_product);
                      }}
                    >
                      <Ellipsis lines={3}>{need_product.title}</Ellipsis>
                    </a>
                  </div>
                </div>
              </div>
              <div
                style={{ position: 'absolute' }}
                className={styles.replaceIcon}
              >
                <i class="material-icons">arrow_back</i>
              </div>
              <div className={styles.replaceAli}>
                <div className={styles.replaceTitle}>
                  {!window.TMALL_USER ? (
                    intl.get('mapping.replace_product.ali_express')
                  ) : (
                    <Select
                      getPopupContainer={triggerNode => triggerNode.parentNode}
                      value={this.state.replaceSelectValue}
                      onChange={this.replaceChangeSelectHandler}
                    >
                      <Select.Option value={2}>
                        {intl.get('mapping.replace_product.ali_express')}
                      </Select.Option>
                      <Select.Option value={4}>
                        {intl.get('mapping.replace_product.tm_express')}
                      </Select.Option>
                    </Select>
                  )}
                </div>
                {changeAe ? (
                  <Spin
                    indicator={<Icon type="loading" />}
                    spinning={aeLoading}
                  >
                    <div className={styles.container}>
                      <img src={this.aeDetailsImg} />
                      <div
                        className={styles.aeDetails}
                        style={{ maxWidth: '16.125rem' }}
                      >
                        <a
                          role="button"
                          tab-index="0"
                          onClick={() => {
                            this.productOpenOnAe(aeDetails);
                          }}
                        >
                          <Ellipsis lines={3}>{aeDetails.subject}</Ellipsis>
                        </a>
                        <i
                          onClick={this.deleteReplaceProduct}
                          class="material-icons"
                        >
                          close
                        </i>
                      </div>
                    </div>
                  </Spin>
                ) : (
                  <div className={styles.container}>
                    <Input
                      style={{ height: '3rem' }}
                      value={aeUrl}
                      onChange={this.changeAeUrl}
                      placeholder={intl.get(
                        'mapping.replace_product.replace_placeholder'
                      )}
                    />
                  </div>
                )}
                {aeUrlError && (
                  <div className={styles.urlErrorText}>
                    {intl.get('public.search_input_url_invalid_tip')}
                  </div>
                )}
                {supplierUrlError && (
                  <div className={styles.urlErrorText}>
                    {this.state.replaceSelectValue === 4
                      ? intl.get('public.search_input_url_error_match_tm_tip')
                      : intl.get('public.search_input_url_error_match_ae_tip')}
                  </div>
                )}
              </div>
            </div>
            <div className={changeAe ? styles.replaceBtn : styles.disabledBtn}>
              <Button
                onClick={() => {
                  this.setState({ replaceStatus1: false }, () => {
                    this.deleteReplaceProduct();
                  });
                }}
              >
                {intl.get('mapping.replace_product.cancle')}
              </Button>
              <Button
                onClick={this.openAeImport}
                className={styles.replaceModalsubmit}
                disabled={!changeAe || supplierUrlError}
              >
                {intl.get('mapping.replace_product.btn_title')}
              </Button>
            </div>
          </Modal>
        ) : null}
        {replaceDrawer && (
          <Drawer
            style={{ borderRadius: 12 }}
            placement="right"
            className="souti"
            title={intl.get('import_list.edit_detail')}
            closable={true}
            onClose={() => {
              this.setState({
                replaceDrawer: false
              });
            }}
            visible={true}
            width={1008}
            destroyOnClose={true}
          >
            <ReplaceMyProdcutEdit
              pid={need_product.id}
              producteDetail={newProductDetails}
              onHide={this.hideDetail}
              originProductData={need_product}
              handleClose={this.handleClose}
              getReplaceId={this.getReplaceId}
              getReplaceTime={this.props.getReplaceTime}
              deleteReplaceProduct={this.deleteReplaceProduct}
            />
          </Drawer>
        )}
        {/* 批量修改 Agencies */}
        {window.CAINIAO_USER || CAINIAO_USER ? (
          <BatchAgencies
            batchAgenciesDrawerVisible={batchAgenciesDrawer}
            batchAgenciesDrawerClose={this.batchAgenciesDrawerClose}
            batchAgenciesList={this.batchAgenciesList}
          />
        ) : null}
      </>
    );
  }
}
