import React from 'react';
import { connect } from 'dva';
import {
  Modal,
  Row,
  Col,
  Select,
  Input,
  Icon,
  List,
  Avatar,
  Popover,
  Checkbox,
  message,
  Alert,
  Button
} from 'antd';

import InfiniteScroll from 'react-infinite-scroller';
import intl from 'react-intl-universal';

import { replaceUrlToHttps, CommonDataCollect } from 'utils/utils';
import styles from './ManageProduct.less';
import './ManageProduct.css';
import { GuildeAction, LinkStoreAction } from 'actions'
const {Option} = Select;
const {Search} = Input;
const ListItem = List.Item;
const CheckboxGroup = Checkbox.Group;

function StoreItem(store) {
  return (
    <Option key={store.id} value={store.id}>
      {store.shop_name}
    </Option>
  );
}

@connect(({ loading }) => ({
  listLoading: loading.effects['productmy/shopifylist'],
  importLoading: loading.effects['productmy/importDsers']
}))
export default class ManageProduct extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      dsersCheckList: [],
      hasMore: true,
      disable: true
    };

    this.storeId = '';
    this.whiteListId = [];
    this.limit = 20;
    this.searchKey = '';
    this.listMap = {};
    this.canCheckedProd = [];
    this.allCanCheckedProd = [];
    this.importIds = [];
    this.hasImport = false;
    this.sinceId = null;
    this.refDom = null;
  }

  componentDidMount() {
    this.storeId = this.props.storeId || '';
    this.getList();
  }

  // 从第三方平台直接获取商品列表
  getList = () => {
    const lastProduct =  this.state.list[ this.state.list.length - 1 ];
    const sinceId = lastProduct ? lastProduct.id : '';

    if( this.sinceId === sinceId ){
      return;
    }

    this.sinceId = sinceId;

    this.props.dispatch({
      type: 'productmy/shopifylist',
      payload: {
        data: {
          store_id: this.storeId,
          since_id: sinceId,
          limit: this.limit,
          title: encodeURIComponent(this.searchKey)
        },
        // message: intl.get('product.manage_product.import_shopify_Copywriting'),
        callback: d => {
          if (d.code === 2000) {
            const { prods = [] } = d.data;
            const allProds = [...this.state.list, ...this.setList(d.data)];
            this.setCanCheckedProd(allProds);
            this.setListMap(allProds);
            this.setState({
              list: allProds,
              hasMore: prods.length === this.limit
            });
          } else if (d.code === 5000) {
            // message.error(intl.get('product.manage_product.import_shopify_Copywriting'))
          }
          this.setState({ disable: false });
        }
      }
    });
  };

  /**
   * 设置可以勾选的个商品
   * @param {Array} prods 所有商品
   */
  setCanCheckedProd = ( prods, checkedList ) => {
    const {
      dsersCheckList,
      list
    } = this.state;
    const prodsArr = prods || list;
    const checkedListArr = checkedList || dsersCheckList;

    this.allCanCheckedProd = [];
    this.canCheckedProd = [];

    prodsArr.forEach( prod => {
      if( !prod.isDsers && prod.status !== 'succ' ){
        this.allCanCheckedProd.push( prod.id );
      }
    })

    if( ( checkedListArr.length === 10 && this.allCanCheckedProd.length >= 10 ) || ( this.allCanCheckedProd.length < 10 && checkedListArr.length === this.allCanCheckedProd.length ) ){
      this.canCheckedProd = [ ...checkedListArr ];
    }else{
      this.canCheckedProd = [ ...this.allCanCheckedProd ];
    }

  }

  // 商品map
  setListMap = list => {
    list.forEach(item => {
      this.listMap[item.id] = item;
    });
  };

  // 提取产品数据
  setList = data => {
    const dProds = new Map();
    const {
      prods,
      sprods
    } = data;

    if( !prods ){
      return [];
    }

    if( sprods ){
      data.sprods.forEach(d => {
        dProds.set(d.detail.id, d);
      });
    }

    return data.prods.map(prod => {
      const obj = {};
      obj.id = prod.id;
      obj.title = prod.title;
      obj.image = prod.image ? prod.image.src : '';
      obj.isDsers = false;
      obj.status = '';
      if (dProds.get(prod.id)) {
        obj.isDsers = true;
        obj.pid = dProds.get(prod.id).id;
      }
      return obj;
    });
  };

  // 设置产品后面的图标
  setProductToolIcon = item => {
    if( this.importIds.includes( item.id ) ) {
      return <Icon type="loading" />;
    }
    if (item.status === 'succ') {
      return (
        <div className={styles.tools}>
          <Icon type="check" />
        </div>
      );
    }

    if (item.isDsers) {
      return (
        <div className={styles.tools}>
          <Icon type="check" />
        </div>
      );
    } else {
      return (
        <Icon
          type="import"
          onClick={() => {
            this.importDsers([item.id]);
          }}
        />
      );
    }
  };

  // 更改商店
  changeStore = v => {
    this.storeId = v;
    this.canCheckedProd = [];
    this.setState({
      list: [],
      dsersCheckList: [],
      hasMore: false
    });
    let searchText = '';
    if (this.refDom?.input.state.value) {
      searchText = this.refDom.input.state.value;
    }
    this.searchList(searchText.trim());
  };

  // 搜索
  searchList = v => {
    this.sinceId = null;
    this.searchKey = v.trim();
    this.canCheckedProd = [];
    this.setState(
      {
        list: [],
        dsersCheckList: [],
        hasMore: true,
        disable: true
      },
      this.getList
    );
  };

  // 同步商品到dsers平台
  importDsers = ids => {
    const { list } = this.state;
    this.importIds = ids;
    this.hasImport = true;
    if (!ids.length) {
      return;
    }

    this.props.dispatch({
      type: 'productmy/importDsers',
      payload: {
        data: {
          store_id: this.storeId,
          ids
        },
        callback: d => {
          if (d.msg === 'product quota err') {
            localStorage.setItem('passivity', 'true');
            //  跳转订阅 && 弹窗拦截
            // window.EVENT.emit(GuildeAction.TurnPlan_Pop, `r-${Date.now()}`, true);
            //  window.location.href = '/app/pricing';
            window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'ProductsLimit')
            return
          }

          this.importIds = [];
          if (d.code === 2010) {
            CommonDataCollect({
              action: 'MP-Import',
              event_type:'import_product'
            })
            ids.forEach(id => {
              this.listMap[id].status = 'succ';
              this.listMap[id].isDsers = true;
            });
          }

          const arr = [...list];
          this.setCanCheckedProd(arr);
          this.setState({
            list: arr,
            dsersCheckList: []
          });

        }
      }
    });
  };

  // 单个选择
  changeChecked = checkedList => {
    this.setCanCheckedProd( undefined, checkedList);
    this.setState({
      dsersCheckList: checkedList
    });
  };

  // 全选
  checkAll = e => {
    const {
      dsersCheckList
    } = this.state;

    let arr = e.target.checked ? [ ...dsersCheckList ] : [];

    if( e.target.checked ){
      if( this.allCanCheckedProd.length < 10 ){
        arr = [ ...this.allCanCheckedProd ]
      }else{
        this.allCanCheckedProd.forEach( prodId => {
          if( arr.length < 10 && !dsersCheckList.includes( prodId ) ){
            arr.push( prodId );
          }
        })
      }
    }

    this.setCanCheckedProd( undefined, arr);

    this.setState({
      dsersCheckList: arr
    })
  }

  render() {
    const {
      showModal,
      hideModal,
      storeList = [],
      listLoading,
      whitelistLoading,
      importLoading
    } = this.props;

    const {
      list,
      hasMore,
      dsersCheckList,
      disable
    } = this.state;

    return (
      <Modal
        title={intl.get('product.manage_product.title1')}
        visible={showModal}
        onCancel={() => hideModal(this.hasImport)}
        footer={null}
        maskClosable={false}
        width={868}
        className={styles.modalWrap}
        wrapClassName="soutivc"
      >
        {/* <Alert
          type="info"
          showIcon
          style={{ marginBottom: 18 }}
          description={intl.getHTML('product.manage_product.bulk_import')}
        /> */}
        <div className="importSelectBox">
          <i class="material-icons">error_outline</i>
          <p>{intl.getHTML('product.manage_product.nombre')}</p>
        </div>
        <div className="tiobox">
          <div className="box-top">
              <div className="l">
                <i class="material-icons notranslate">storefront</i>
                <Select
                  dropdownMatchSelectWidth={false}
                  className={styles.storeSelect}
                  placeholder="Store"
                  defaultValue={this.storeId}
                  onChange={this.changeStore}
                  disabled={true}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  // suffixIcon={<img class="icon_down" src={require('../../assets/newImportList/icon_expand_more-24px.png')} />}
                  suffixIcon={<></>}
                >
                  {storeList.map(StoreItem)}
                </Select>
              </div>
              <div className="r">
                <Search
                  placeholder={intl.get(
                    'product.manage_product.search_placeholder'
                  )}
                  onSearch={this.searchList}
                  disabled={disable}
                  enterButton
                  ref={node => {
                    this.refDom = node;
                  }}
                />
              </div>
          </div>
          <div className="headerWrap">
            <Checkbox
              indeterminate={dsersCheckList.length > 0 && dsersCheckList.length !== this.canCheckedProd.length}
              disabled={this.canCheckedProd.length === 0}
              checked={dsersCheckList.length === this.canCheckedProd.length && this.canCheckedProd.length !== 0}
              onChange={this.checkAll}
            >Multiple Select</Checkbox>
            <span className="desc">({intl.get('product.manage_product.import_max_tip')})</span>
            <div className="selectProductButton">
              <Button
                data="dx"
                disabled={this.state.dsersCheckList.length === 0}
                type="primary"
                onClick={() => this.importDsers(dsersCheckList)}
              >{intl.get('product.manage_product.import_btn')}</Button>
            </div>
          </div>
        </div>
        <div className="list_wq">
          <div className={styles.contentWrap}>
            <div
              className={styles.productList}
            >
              <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                useWindow={false}
                loadMore={this.getList}
                hasMore={listLoading && hasMore}
              >
                <CheckboxGroup
                  onChange={this.changeChecked}
                  value={dsersCheckList}
                >
                  <List
                    loading={listLoading || importLoading || whitelistLoading}
                    dataSource={list}
                    locale={{
                      emptyText:
                        listLoading || whitelistLoading
                          ? intl.get('product.manage_product.syncing')
                          : intl.get('product.manage_product.no_data')
                    }}
                    renderItem={item => (
                      <ListItem
                        key={item.id}
                        actions={[this.setProductToolIcon(item)]}
                      >
                        <ListItem.Meta
                          avatar={
                            <Popover
                              placement="leftTop"
                              trigger="hover"
                              content={
                                <div>
                                  <img
                                    src={replaceUrlToHttps(item.image)}
                                    style={{
                                      width: '260px',
                                      height: '260px',
                                      objectFit: 'contain'
                                    }}
                                  />
                                </div>
                              }
                            >
                              <div className="imgwq">
                                <div className="checkw">
                                  <Checkbox
                                      value={item.id}
                                      disabled={this.canCheckedProd.includes( item.id )===false}
                                    />
                                </div>
                                <Avatar
                                  src={replaceUrlToHttps(item.image)}
                                  shape="square"
                                  size="large"
                                />
                              </div>
                            </Popover>
                          }
                          title={
                            <span
                              className={!item.isDsers ? styles.dsersProduct : null}
                            >
                              {item.title}
                            </span>
                          }
                        />
                      </ListItem>
                    )}
                  />
                </CheckboxGroup>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
