import React from 'react';
import { connect } from 'dva';
import {
  Form,
  Input,
  Button,
  Table,
  InputNumber,
  Icon,
  Modal,
  message,
  Tabs,
  Row,
  Col,
  Alert,
  Spin,
  Tooltip,
  Switch
} from 'antd';

import numeral from 'numeral';
import intl from 'react-intl-universal';
import round from 'lodash/round';

import SelectImageManagement from './Common/SelectImage.js';
import ImagesList from './Common/ImageList.js';
import OrganizationNoEdit from './component/OrganizationNoEdit';

import { getAliExpressUrl, getSupplierUrl, replaceUrlToHttps } from '../../utils/utils';
import { getPriceCheck, setPriceCheck } from '../../services/productmy';
import ShippingDetail from './component/ShippingDetail';
import { currencySymbolMap } from '../../common/sysconfig';

import styles from './MyProductEdit.less';
import './importList.css';
import './MyProductEdit.css';

import defaultImg from '../../assets/default.svg';
import AgencyShippingDetail from './component/AgencyShippingDetail.jsx';

const { TabPane } = Tabs;
const { confirm } = Modal;

const opitonColor = ['#52c41a', '#722ed1', '#f90'];
const mapTypeMap = {
  1: 'basic',
  2: 'advance',
  3: 'bogo',
  4: 'bundle',
  5: 'standard'
};

// 价格自动同步适用的套餐类型 2: advanced 3: pro 5: business 6: enterprise
const acceptOrder = [2,3,5,6,8,10];

/* eslint react/no-array-index-key: 0 */
@connect(({ productmy, global, loading, login }) => ({
  productmy,
  storeCurrency: global.storeCurrency,
  loading: loading.models.productmy,
  getProdLoading: loading.effects['productmy/shopifyProductDetail'],
  customizeCurrency: login.customizeCurrency
}))
@Form.create()
export default class MyProdcutEdit extends React.PureComponent { 
  constructor(props) {
    super(props);
    this.state = {
      producteDetail: {},
      showImages: [],
      bShowModal: false,
      salesPrice: '',
      selectedRows: [],
      showEditSku: false,
      showDeleteSku: false,
      value: 0,
      warningTips: false,
      packageType: false,
      priceRuleStatus: false,
      settingStatus: false,
      settingType: 1,
      priceChecked: true,
      aeProductDetail:[],
      aeProduct:{}
    };
  }

  componentWillMount() {
    this.setState({
      producteDetail: this.props.producteDetail.prod
    });
  }

  componentDidMount() {
    const { producteDetail } = this.props;
    this.disabledFlag = !!producteDetail.is_pub;
    this.getShopifyProductDetail(producteDetail);

    this.getPriceCheck();
  }

  componentDidUpdate(a,b,c){
    if(this.state.aeProductDetail.length !== b.aeProductDetail.length){
      this.setAEStock();
    }
  }

  // 获取 shopify 商品详情
  getShopifyProductDetail = producteDetail => {
    this.getProdLoading = true;
    this.props.dispatch({
      type: 'productmy/shopifyProductDetail',
      payload: {
        data: {
          id: producteDetail.id
        },
        callback: d => {
          if (d.code === 2000) {
            this.initData(d.data);
            this.getAEDetail(producteDetail);
          }
        }
      }
    });
  };

  /**
   * 获取自动更新价格开关状态
   * @param {} 
  */
  getPriceCheck = () => {

    const { producteDetail: {id, storeId} = producteDetail } = this.props;
    const { type = 0 } = JSON.parse(localStorage.getItem('payment'));
    const packageType = acceptOrder.includes(type);

    getPriceCheck({
      data: {
        store_id: storeId
      }
    }).then(res => {
      if(res.code === 2000 && res.data){
        const data = res.data;
        this.setState({
          priceRuleStatus: data.product_bool,
          settingStatus: data.setting_bool,
          settingType: data.type,
        })
      }
    })

    this.setState({
      packageType: packageType
    })
  }

  getAEDetail = producteDetail => {
    const {
      prod: {
        ext: { basic = {}, standard = {} }
      }
    } = producteDetail;

    Object.entries(basic).forEach(item => {
      if (item[1].is_default) {
        this.aePid = item[0];
      }
    });

    if(producteDetail?.prod?.ext?.map_type !== 1){
        this.aePid = ""
      }

    let isSameSupplier = []
    if (
      producteDetail?.prod?.ext?.standard &&
      producteDetail?.prod?.ext?.map_type == 5
    ) {
      Object.values(producteDetail?.prod?.ext?.standard).forEach(b => {
        const { is_default, variant_supply = [] } = b;
        isSameSupplier.push({
          id: variant_supply[0].supply[0].platform_product_id,
          supplier_type: variant_supply[0].supply[0]?.platform_type
        });
      });
    }
    const obj = {}
    isSameSupplier.forEach(item => {
      obj[item.id] = item;
    });
    let Array = [] //存放去重过后的数据
    let newobj = {}   
    //去除data,里面title相同的对象
    for (var i = 0; i < Object.values(obj).length; i++) {
      if (!newobj[Object.values(obj)[i].id]) {
        Array.push(Object.values(obj)[i])
        newobj[Object.values(obj)[i].id] = true
      } 
    }
   
    const len = Array.length
    let err4290 = 0
    // console.log([...new Set(isSameSupplier)],'-------------new Set(isSameSupplier)-------');
    if(Array?.length){
      for (let index = 0; index < Array?.length; index++) {
        this.props.dispatch({
          type:Array[index].supplier_type === 4 ? 'productmy/tmProductDetail' : 'productmy/aeProductDetail',
          payload: {
            data: {
              url:getSupplierUrl(Array[index].supplier_type,Array[index].id),
              country:this.props.producteDetail.prod.ship_to,
              product_id:Array[index].id,
              supplier_type:Array[index].supplier_type
            },
            callback:(res)=>{
              if(res.code == 4290){
                err4290 += 1
              }
              const {
                aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] }
              } = res.data;
              this.aeProduct = res.data
              if(res.data.product_status_type !== "onSelling"){
                aeop_ae_product_sku.forEach(item=>{
                  item.onselling = false
                })
              }else{
                aeop_ae_product_sku.forEach(item=>{
                  item.onselling = true
                })
              }
              this.setState({
                aeProductDetail:[...aeop_ae_product_sku,...this.state.aeProductDetail],
              })
              this.aeProductDetail = [...aeop_ae_product_sku,...this.aeProductDetail]
            }
          }
        });
        
      }
    }else{
      if (this.aePid) {
        this.props.dispatch({
          type:producteDetail?.suppliers[0] === 4 ? 'productmy/tmProductDetail' : 'productmy/aeProductDetail',
          payload: {
            data: {
              url:getSupplierUrl(producteDetail?.suppliers[0],this.aePid),
              country:this.props.producteDetail.prod.ship_to,
              product_id:this.aePid,
              supplier_type:producteDetail?.suppliers[0]
            },
            callback: d => {
              if (d.code == 2000) {
                this.setState({
                  warningTips: false
                });
                const {
                  aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] }
                } = d.data;
                this.state.aeProductDetail = aeop_ae_product_sku;
                this.aeProduct = d.data;
                this.setAEStock();
              } else if (d.code === 4290) {
                this.setState({
                  warningTips: true
                });
                return null;
              }
            }
          }
        });
      }
    }
    this.setAEStock();
    // console.log(this.state.aeProductDetail,this.state.aeProduct,this.aePid,'--------ff------');
    if(err4290){
      this.setState({
        warningTips: true
      });
    }else{
      this.setState({
        warningTips: false
      });
    }
    // if(len == 1){
    //   this.aePid = isSameSupplier[0]
    // }

    // if (this.aePid) {
    //   this.props.dispatch({
    //     type: 'productmy/aeProductDetail',
    //     payload: {
    //       data: {
    //         url: getAliExpressUrl(this.aePid),
    //         country:this.props.producteDetail.prod.ship_to
    //       },
    //       callback: d => {
    //         if (d.code == 2000) {
    //           this.setState({
    //             warningTips: false
    //           });
    //           const {
    //             aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] }
    //           } = d.data;
    //           this.aeProductDetail = aeop_ae_product_sku;
    //           this.aeProduct = d.data;
    //           this.setAEStock();
    //         } else if (d.code === 4290) {
    //           this.setState({
    //             warningTips: true
    //           });
    //           return null;
    //         }
    //       }
    //     }
    //   });
    // }
  };

  getProdLoading = true;
  aeProductDetail = []; // ae 商品的的sku列表
  aeProduct = {}; // 整个 ae 商品
  aepid = '';

  // 设置
  setAEStock = () => {
    const { producteDetail: { detail: { variants } } } = this.state;
    let flag = false;
    if (
      this.aeProductDetail.length == 1 &&
      this.aeProductDetail[0].id == '<none>'
    ) {
      // mapping的主供应商只有一个sku
      flag = true;
    }

    variants.forEach(item => {
      const variantNewSkuId = this.getAESkuIdByVariantId(item.id, item.sku);

      if (this.aeProduct.product_status_type !== 'onSelling') {
        item.aeStock = 0;
        return;
      }
      this.state.aeProductDetail.forEach(ae => {
        let arr = variantNewSkuId.split(';')
        let arr1 = item.sku.split(';')
        if (
          flag ||
          variantNewSkuId.split(';').sort().join(';') === ae.id.split(';').sort().join(';') 
          ) {
          if (ae.s_k_u_available_stock != undefined) {
            item.aeStock = ae.s_k_u_available_stock;
          } else {
            item.aeStock = 0;
          }
        }
      });
    });
  };

  // 初始化数据
  initData = data => {
    this.getProdLoading = false;
    this.setVariantId(data);
    this.pushActionOptions(data);
    this.setState({
      producteDetail: data,
      showImages: this.getImages(data),
      priceChecked: data?.ext?.sync_price_status === 1 ? true : false
    });
  };

  // 设置图片内容
  getImages = data => {
    const {
      detail: { images = [], image = {} }
    } = data;

    images.sort((a, b) => (a.src == image.src ? -1 : 0));

    images.forEach(item => {
      if (this.disabledFlag && item.variant_ids == undefined) {
        item.variant_ids = [];
        return;
      }
      if (!this.disabledFlag && item.alt == undefined) {
        item.alt = '';
      }
    });

    return images;
  };

  onSubmit = isPublish => {
    this.submitTrue(isPublish);
  };

  submitTrue = isPublish => {
    const { form } = this.props;
    const { validateFields } = form;
    const {
      producteDetail,
      producteDetail: { detail },
      showImages
    } = this.state;

    if (this.hasRepeatOptionValue()) {
      message.error(
        'The product\'s colors and/or sizes are repeated, please change them'
      );
      return;
    }

    validateFields((err, values) => {
      if (!err) {
        detail.variants &&
          detail.variants.forEach(item => {
            item.price += '';
            item.compare_at_price += '';
            item.inventory_quantity =
              item.aeStock == undefined
                ? item.inventory_quantity
                : item.aeStock;
            this.updataExt(item);
            this.updataImages(item);
            delete item.aeStock;
            delete item.originSku;
          });

        detail.title = values.title;
        detail.product_type = values.product_type;
        detail.body_html = this.descriptionHtml || '';
        detail.vendor = values.vendor;
        detail.images = showImages;
        this.props.onSubmit(producteDetail.id, producteDetail, isPublish);
      }
    });
  };

  // 如果sku改变 images里的sku索引也要更新
  updataImages = variant => {
    const { showImages } = this.state;

    if (variant.sku == variant.originSku) {
      return;
    }

    showImages.forEach(item => {
      if (item.alt) {
        let arr = item.alt.split('|');
        if (arr.includes(variant.originSku)) {
          arr = arr.filter(alt => alt != variant.originSku);
          arr.push(variant.sku);
          item.alt = arr.join('|');
        }
      }
    });
  };

  // 如果sku改变ext中的sp的sku也要变
  updataExt = variant => {
    const { basic } = this.state.producteDetail.ext || {};

    basic &&
      Object.values(basic).forEach(item => {
        item.is_default &&
          item.supply.forEach(sp => {
            if (sp.sku == variant.originSku) {
              sp.sku = variant.sku;
              sp.sku_desc = variant.originSku;
            }
          });
      });
  };

  // 型号的值有没有重复的 例如2个 red / s
  hasRepeatOptionValue = () => {
    const {
      producteDetail: { detail: { variants = [], options = [] } }
    } = this.state;

    const arr = [];
    let repeat = false;

    variants.forEach(item => {
      let v = '';
      options.forEach((option, i) => {
        v += item[`option${i + 1}`];
      });

      if (arr.includes(v)) {
        repeat = true;
      } else {
        arr.push(v);
      }
    });

    return repeat;
  };

  getVariantImage = variant => {
    let v = '';

    this.state.showImages.forEach(image => {
      if (image.alt && variant.originSku) {
        const arr = image.alt.split('|');
        if (arr.includes(variant.originSku)) {
          v = image.src;
          return;
        }
      }
      if (image.variant_ids && image.variant_ids.includes(variant.id)) {
        v = image.src;
      }
    });

    return v || defaultImg;
  };

  // 获取商品成本
  getCost = variant => {
    let cost = 0;
    const item_offer_site_sale_price = this.aeProduct.item_offer_site_sale_price;
    const variantNewSkuId = this.getAESkuIdByVariantId(variant.id, variant.sku);
    
    let arr = variant.originSku.split(';')
    let arr1 = variantNewSkuId?.split(';')

    if(this.state.producteDetail?.ext?.map_type == 5){
      this.state.aeProductDetail.forEach(item => {
        if (item.id === '<none>') {
          cost = item_offer_site_sale_price || item.offer_sale_price || item.sku_price;
          return;
        }
        
        if (item.id.split(';').sort().join(';') == arr.sort().join(';') || 
            item.id.split(';').sort().join(';') == arr1.sort().join(';')) {
          cost =
            item.offer_sale_price || item_offer_site_sale_price || item.sku_price;
        }
      });
      return cost
    }

    this.state.aeProductDetail.forEach(item => {
      
      if (item.id === '<none>') {
        cost = item_offer_site_sale_price || item.offer_sale_price || item.sku_price;
        return;
      }
      if (
        item.id == variant.originSku ||
        item.id == variantNewSkuId ||
        variantNewSkuId.split(';').sort().join(';') === item.id.split(';').sort().join(';')
      ) {
        cost =
          item.offer_sale_price || item_offer_site_sale_price || item.sku_price;
      }
    });
    return cost;
  };

  getStandardAeStock = (variant)=>{
    let stock = 0;
    const variantNewSkuId = this.getAESkuIdByVariantId(variant.id, variant.sku);
    let arr = variant.originSku.split(';')
    let arr1 = variantNewSkuId?.split(';')

    this.state.aeProductDetail.forEach(item => {
      if(item.onselling === false){
        stock = 0
      }
      if (item.id == variantNewSkuId || 
        item.id.split(';').sort().join(';') == arr.sort().join(';') || 
        item.id.split(';').sort().join(';') == arr1.sort().join(';')) {
        stock =
          item.s_k_u_available_stock || item.ipm_sku_stock;
      }
    });
    return stock
  }

  getAESkuIdByVariantId = (variantId, sku) => {
    const { basic, standard } = this.state.producteDetail.ext || {};
    let v = '';

    if(this.state.producteDetail?.ext?.map_type == 5){
      if (variantId && standard) {
        Object.values(this.state.producteDetail?.ext?.standard).forEach(b => {
          const { is_default, variant_supply = [] } = b;
          if (variant_supply[0].supply[0].variant_id == variantId || variant_supply[0].supply[0].sku_edit === sku) {
            v = variant_supply[0].supply[0].sku
          }
        });
      }
    }else if (variantId && basic) {
      Object.values(basic).forEach(item => {
        if (item.is_default && item.supply) {
          item.supply.forEach(sp => {
            if (sp.variant_id == variantId || sp.sku_edit === sku || sp.sku === sku) {
              v = sp.sku;
            }
          });
        }
      });
    }
    return v || '';
  };

  getColumns = (currencyCode, currencySymbol, isNotUSD, currencyRates, map_type) => {
    const {
      producteDetail: {
        detail: { options = [] },
        ext = {}
      }
    } = this.state;
console.log(this.state.producteDetail,'_+_+_++_+_+_');
    let isSameSupplier = []
    if(this.state.producteDetail?.ext?.standard){
      Object.values(this.state.producteDetail?.ext?.standard).forEach(b => {
       const { is_default, variant_supply = [] } = b;
       isSameSupplier.push(variant_supply[0].supply[0].platform_product_id);
     });
    }
    const len = new Set(isSameSupplier).size

    const mapType = ext?.map_type;
    const skuRow = [
      {
        title: intl.get('product_edit.picture'),
        dataIndex: 'image_src',
        width: 100,
        render: (v, record) => {
          return (
            <a
              onClick={() => {
                this.showChangeModal(record);
              }}
            >
              <img
                src={this.getVariantImage(record)}
                style={{ height: 64, width: 64 }}
              />
            </a>
          );
        }
      },
      {
        title: intl.get('product_edit.sku'),
        dataIndex: 'sku',
        width: 116,
        render: (sku, record) => {
          return (
            <Input
              width={116}
              value={sku}
              name={record.id}
              disabled={this.disabledFlag}
              onChange={e => {
                this.setVariantSKU(record.rowId, e.target.value);
              }}
            />
          );
        }
      },
      {
        title: intl.get('product_edit.cost'),
        dataIndex: 'purchase_cost',
        width: 60,
        render: (v, record) => {
          const cost = this.getCost(record);
          if ([2, 3, 4].includes(mapType)) {
            return 'Multiple';
          }
          if (this.props?.customizeCurrency?.storeId === this.state.producteDetail.store_id
            && this.props?.customizeCurrency?.id === this.state.producteDetail.user_id && isNotUSD) {
            return (
              <>
                {`$${numeral(cost).format('0,0.00')}`}
                <br />
                <span className={styles.currencyCostValue}>
                  {`${currencySymbol}${round(cost * Number(this.props?.customizeCurrency?.currency), 2)}`}
                </span>
              </>
            );
          }
          if (isNotUSD) {
            return (
              <>
                {`$${numeral(cost).format('0,0.00')}`}
                <br />
                <span className={styles.currencyCostValue}>
                  {`${currencySymbol}${round(cost * currencyRates, 2)}`}
                </span>
              </>
            );
          }
          return `$${numeral(cost).format('0,0.00')}`;
        }
      },
      {
        title: intl.get('product_edit.price'),
        dataIndex: 'price',
        width: 100,
        render: (price, record) => {
          return (
            <InputNumber
              disabled={this.disabledFlag}
              formatter={value =>
                `${currencySymbol || '$'} ${value}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '100%' }}
              min={0}
              precision={2}
              value={price}
              onChange={value => {
                this.setVariantPirce(record.rowId, value);
              }}
            />
          );
        }
      },
      {
        title: intl.getHTML('product_edit.compare_price'),
        dataIndex: 'compare_at_price',
        width: 100,
        render: (price, record) => {
          return (
            <InputNumber
              disabled={this.disabledFlag}
              formatter={value =>
                `${currencySymbol || '$'} ${value}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '100%' }}
              min={0}
              precision={2}
              value={price || 0}
              onChange={value => {
                this.setVariantComparePirce(record.rowId, value);
              }}
            />
          );
        }
      },
      {
        title: (
          <div>
            {intl.getHTML('product_edit.stock_on_shopify')}
            <Tooltip
              title={intl.getHTML('product_edit.shopify_product_stock_tip')}
            >
              <Icon
                style={{ marginLeft: 5 }}
                type="question-circle"
                className={styles.stockTip}
              />
            </Tooltip>
          </div>
        ),
        dataIndex: 'inventory_quantity',
        width: 100,
        render: v => {
          return <InputNumber disabled={this.disabledFlag} value={v || 0} />;
        }
      }
    ];

    if (ext[mapTypeMap[ext?.map_type]]) {
      skuRow.push({
        title: (
          <div>
            {intl.getHTML('product_edit.stock')}
            <Tooltip
              placement="topRight"
              title={intl.getHTML('product_edit.product_stock_tip')}
            >
              <Icon style={{marginLeft:3}} type="question-circle" className={styles.stockTip} />
            </Tooltip>
          </div>
        ),
        dataIndex: 'aeStock', 
        width: 100,
        render: (v,record) => {
          const aestock = this.getStandardAeStock(record)
          if (ext?.map_type !== 1 && ext?.map_type !== 5) {
            return 'Multiple';
          }
          if(ext?.map_type == 5){
            return <InputNumber disabled={this.disabledFlag} value={aestock || 0} />;
          }
          return <InputNumber disabled={this.disabledFlag} value={v || 0} />;
        }
      });
    }

    const names = [];
    options.forEach((item, ind) => {
      const dataindex = `option${ind + 1}`;

      if (ind >= 2) {
        names.push(item.name);
      } else {
        skuRow.splice(2 + ind, 0, {
          title: (
            <Input
              width={72}
              disabled={this.disabledFlag}
              value={item.name}
              name={item.id}
              onChange={e => {
                this.changeOptionTitle(e, ind);
              }}
            />
          ), // `${item.name}`
          dataIndex: dataindex,
          width: 72,
          render: (dataindex, record) => {
            return (
              <Input
                width={72}
                disabled={this.disabledFlag}
                value={dataindex}
                name={record.id}
                onChange={e => {
                  this.setVariants(record.rowId, e.target.value, ind + 1);
                }}
              />
            );
          }
        });
      }
    });

    if (options.length > 2) {
      skuRow.splice(4, 0, {
        title: <>
        
        {names[0].length > 20 ? <Tooltip placement={names[0].length > 20 ? "topLeft":null} title={names[0]}>{names[0]}</Tooltip> : names[0]}</>,
        dataIndex: 'option3',
        width: 100,
        render: (option3, record) => {
          return (
            <Input
              disabled={this.disabledFlag}
              value={option3}
              name={record.id}
              onChange={e => {
                this.setVariants(record.rowId, e.target.value, 2 + 1);
              }}
            />
          );
        }
      });
    }

    switch (map_type) {
      case 6:
        skuRow.forEach((item, index) => {
          switch (item.dataIndex) {
            case "purchase_cost":
              skuRow.splice(index, 1)
              break;
            case "aeStock":
              skuRow.splice(index, 1)
              break;
            default:
              break;
          }
        })
        break;
    
      default:
        break;
    }

    return skuRow;
  };

  setVariantId = data => {
    const { detail = {} } = data;
    const { variants = [] } = detail;
    let id = 0;
    variants.forEach(variant => {
      const variantData = variant;
      id += 1;
      variantData.rowId = id;
      variantData.price =
        Number(variantData.price) || Number(variantData.purchase_cost) || 0;
      variantData.sku = variantData.sku || 'Default';
      variantData.originSku = variantData.sku || 'Default';
      this.variantMap[id] = variant;
    });
    this.rowId = id + 1;
  };

  // 库存变化
  changeStock = (id, value) => {
    let { producteDetail } = this.state;
    const data = this.variantMap[id];
    data.aeStock = value;
    data.inventory_quantity = value;
    producteDetail = { ...producteDetail };
    this.setState({ producteDetail });
  };

  // Compare price变化
  setVariantComparePirce = (id, value) => {
    let { producteDetail } = this.state;
    const data = this.variantMap[id];
    data.compare_at_price = value || 0;
    producteDetail = { ...producteDetail };
    this.setState({ producteDetail });
  };

  // 更改variant的price
  setVariantPirce = (id, value) => {
    let { producteDetail } = this.state;
    const data = this.variantMap[id];
    data.price = value || 0;
    producteDetail = { ...producteDetail };
    this.setState({ producteDetail });
  };

  // 批量更改variants的price
  setVariantPirceBatch = () => {
    let { producteDetail } = this.state;
    this.selectedRows.forEach(item => {
      item[this.actionType] = this.actionValue;
    });
    producteDetail = { ...producteDetail };
  };

  // 设置variants的options的值
  setVariants = (id, value, ind) => {
    let { producteDetail } = this.state;
    this.variantMap[id][`option${  ind}`] = value;

    if (
      this.actionType != 'price' &&
      this.actionType != 'cost' &&
      this.actionType != 'image_src'
    ) {
      this.upDateSelectVariant(producteDetail);
    }

    producteDetail = { ...producteDetail };
    this.setState({ producteDetail });
  };

  // 设置variants的sku
  setVariantSKU = (id, value) => {
    let { producteDetail } = this.state;
    const sku = this.variantMap[id].sku || this.variantMap[id].tSku;

    const image = this.imageMap[sku];
    if (image) {
      image.image.filename = value;
      this.imageMap[value] = image;
      this.imageSrcMap[image.url] = image;
    }

    this.variantMap[id].sku = value;
    producteDetail = { ...producteDetail };
    this.setState({ producteDetail });
  };

  // 设置商品详情
  changeDescription = html => {
    this.descriptionHtml = html;
  };

  // 确认删除variants的弹窗
  removeVariants = rowId => {
    confirm({
      title: intl.get('product_edit.delete_variant_tip'),
      okText: intl.get('public.yes'),
      cancelText: intl.get('public.no'),
      onOk: () => {
        this.removeVariantsSub(rowId);
      }
    });
  };

  // 确认删除variants
  removeVariantsSub = rowId => {
    let { producteDetail } = this.state;
    const { detail } = producteDetail;
    const variants = [];
    detail.variants.forEach(item => {
      if (rowId !== item.rowId) {
        variants.push(item);
      }
    });
    detail.variants = variants;
    producteDetail = { ...producteDetail };
    this.setState({ producteDetail });
  };

  // 弹出选择variant图片弹窗
  showChangeModal = variant => {
    if (this.state.producteDetail.is_pub) {
      return;
    }
    this.currentSku = variant;
    this.selectHead = false;
    this.setState({
      bShowModal: true
    });
  };

  // 隐藏选择图片弹窗
  hideChangeImage = () => {
    // const { producteDetail } = this.state;
    this.setState({
      bShowModal: false
    });
    this.forceUpdate();
  };

  // 把选择的图片给variants
  selectImageToSku = (image = {}) => {
    const { showImages } = this.state;

    showImages.forEach(item => {
      if (item.alt && item.alt.includes(this.currentSku.sku)) {
        const arr = item.alt
          .split('|')
          .filter(alt => alt != this.currentSku.sku);
        item.alt = arr.join('|');
      }
      if (item.variant_ids && item.variant_ids.includes(this.currentSku.id)) {
        item.variant_ids = item.variant_ids.filter(
          id => id != this.currentSku.id
        );
      }

      if (item.src == image.src) {
        const variantSku = this.currentSku.sku;
        if (item.alt != undefined && !item.alt.includes(variantSku)) {
          let arr = item.alt.split('|');
          arr.push(variantSku);
          arr = arr.filter(sku => sku);
          item.alt = arr.join('|');
          return;
        }

        if (
          item.variant_ids &&
          !item.variant_ids.includes(this.currentSku.id)
        ) {
          item.variant_ids.push(this.currentSku.id);
        }
      }
    });

    this.setState({
      showImages: [...showImages],
      bShowModal: false
    });
  };

  // ae的库存和variants的map
  getAeSkuMap = sku => {
    const obj = {};
    sku = sku || [];
    sku.forEach(item => {
      obj[item.sku_id] = item;
    });
    return obj;
  };

  // 把ae的sku库存赋值给商品 把ae折后价赋值给shopify的成本
  setAEStockInVariants = product => {
    const skus = product.detail.variants;
    const supplier_default = product.supplier_default || [];
    const supplier_defaultOne = supplier_default[0] || '';
    const supplier_sku = product.supplier_sku || {};
    let sku_map = null;
    if (supplier_sku[supplier_defaultOne]) {
      sku_map = supplier_sku[supplier_defaultOne].sku_map;
    }

    skus.forEach(item => {
      item.aeStock = item.inventory_quantity;
    });

    if (product.platfrom_type == 1) {
      const aeSkus = this.getAeSkuMap(product.product_summary.sku_summary);
      let aeSkuFlag = null;
      skus.forEach(item => {
        aeSkuFlag = aeSkus[item.sku];
        if (sku_map) {
          const skuid = sku_map[item.id];
          aeSkuFlag = aeSkus[skuid];
        }
        if (aeSkuFlag) {
          item.purchase_cost = aeSkuFlag.purchase_cost;
        }
      });
    }
  };

  // 返回所有option
  getOptions = producteDetail => {
    const { detail = {} } = producteDetail;
    const { options = [] } = detail;
    const optionsArr = [];
    options.forEach((item, index) => {
      item.values &&
        item.values.forEach(option => {
          optionsArr.push(
            <a
              key={option}
              role="button"
              tab-index="0"
              style={{ color: opitonColor[index] }}
              className={
                this.selectVariantValue == option
                  ? styles.selectVariantValue
                  : ''
              }
              onClick={() => {
                this.clickVariant(index, option);
              }}
            >
              {option}
            </a>
          );
        });
    });
    return optionsArr;
  };

  // 根据option筛选sku row
  clickVariant = (type, option) => {
    if (type == 101) {
      // 点击None
      this.disabledCopyOption();
      this.setState({
        selectedRows: []
      });
      return;
    }

    const { detail = {} } = this.state.producteDetail;
    const { variants = [], options } = detail;
    const arr = [];

    if (type == 100) {
      // 点击All
      variants.forEach(item => {
        arr.push(item.rowId);
      });
      this.setState({
        selectedRows: [...arr]
      });
      this.selectedRows = variants;
      return;
    }

    this.selectedRows = [];
    variants.forEach(item => {
      if (item[`option${type + 1}`] == option) {
        arr.push(item.rowId);
        this.selectedRows.push(item);
      }
    });

    this.copykey = options[type].name.toLowerCase();
    this.copykeyIndex = type + 1;
    this.actionOptions.forEach(item => {
      if (item.key == `d-${  this.copykey}`) {
        item.disabled = false;
      } else if (item.key.includes('d-')) {
        item.disabled = true;
      }
    });

    this.selectVariantRowIds = arr;
    this.selectVariantValue = option;
    this.setState({
      selectedRows: [...arr]
    });
  };

  // 获取批量操作variants的按钮
  pushActionOptions = producteDetail => {
    const { detail: { options = [] } } = producteDetail;

    this.actionOptions.length = 4;
    options.forEach((item, index) => {
      const v = item.name ? item.name.toLowerCase() : `option${index + 1}`;
      this.actionOptions.push({
        key: v,
        name: `Change ${v}`,
        tip: `New ${v}`,
        disabled: false
      });
      this.keys[v] = `option${  index + 1}`;
    });
    options.forEach((item, index) => {
      const v = item.name ? item.name.toLowerCase() : `option${index + 1}`;
      this.actionOptions.push({
        key: `d-${v}`,
        name: `Duplicate in another ${v}`,
        tip: `${v} for duplicated variants`,
        disabled: true
      });
    });
  };

  hideEditSku = () => {
    this.actionType = '';
    this.setState({
      showEditSku: false
    });
  };

  hideDeleteSku = () => {
    this.actionType = '';
    this.setState({
      showDeleteSku: false
    });
  };

  // 批量确认删除variants的弹窗
  removeVariantsAction = () => {
    confirm({
      title: intl.get('product_edit.delete_selected_variant_tip'),
      okText: intl.get('public.yes'),
      cancelText: intl.get('public.cancel'),
      onOk: () => {
        this.deleteSku();
      },
      onCancel: () => {
        this.actionType = '';
      }
    });
  };

  // 删除选中sku
  deleteSku = () => {
    let { producteDetail, selectedRows } = this.state;
    const { detail } = producteDetail;
    const variants = [];
    detail.variants.forEach(item => {
      if (!selectedRows.includes(item.rowId)) {
        variants.push(item);
      }
    });
    detail.variants = variants;
    this.upDateSelectVariant(producteDetail);
    producteDetail = { ...producteDetail };
    this.setState({ producteDetail, showDeleteSku: false, selectedRows: [] });
    this.actionType = '';
  };

  copySku = () => {
    const { producteDetail = {} } = this.state;
    const { detail = {} } = producteDetail;
    const { variants = [], options = [] } = detail;
    this.selectedRows.forEach(item => {
      const obj = { ...item };
      obj[`option${  this.copykeyIndex}`] = this.actionValue;
      obj.rowId = this.rowId;
      variants.push(obj);
      this.rowId += 1;
    });

    options[this.copykeyIndex - 1].values.push(this.actionValue);
  };

  upDateSelectVariant = product => {
    const { detail = {} } = product;
    const { options = [], variants = [] } = detail;
    const optionValue = [{}, {}, {}];

    variants.forEach(item => {
      options.forEach((option, index) => {
        optionValue[index][item[`option${index + 1}`]] =
          item[`option${index + 1}`];
      });
    });

    options.forEach((item, i) => {
      item.values = Object.values(optionValue[i]);
    });

    this.setVariantId(product);
  };

  saveEditSku = () => {
    if (this.actionValue || this.actionValue == 0) {
      // 如果有值或值是0
      let { producteDetail } = this.state;
      if (this.actionType == 'price' || this.actionType == 'compare_at_price') {
        this.setVariantPirceBatch();
      } else if (this.actionType.includes('d-')) {
        this.copySku();
      } else {
        this.selectedRows.forEach(item => {
          item[this.keys[this.actionType]] = this.actionValue;
          if (this.actionType == 'aeStock') {
            item.inventory_quantity = this.actionValue * 1;
          }
        });
      }

      if (
        this.actionType != 'price' &&
        this.actionType != 'cost' &&
        this.actionType != 'image_src'
      ) {
        this.upDateSelectVariant(producteDetail);
      }

      producteDetail = { ...producteDetail };
      this.setState({
        producteDetail,
        showEditSku: false
      });
    } else {
      this.hideEditSku();
    }
    this.actionType = '';
  };

  selectAction = v => {
    v = v.key;
    this.actionType = v;
    if (v == 'delete') {
      this.removeVariantsAction();
      return;
      this.setState({
        showDeleteSku: true
      });

    }
    if (v == 'image_src') {
      this.setState({
        bShowModal: true
      });
    } else {
      this.setState({
        showEditSku: true
      });
    }
  };

  // 改变option分类的name
  changeOptionTitle = (e, index) => {
    const v = e.target.value;
    Promise.resolve(null).then(() => {
      let { producteDetail } = this.state;
      const { detail = {} } = producteDetail;
      const { options = [] } = detail;
      options[index].name = v;
      producteDetail = { ...producteDetail };
      this.setState({
        producteDetail
      });
    });
  };

  // 批量操作sku用户输入的值
  editSkuVal = v => {
    this.actionValue = v;
  };

  getEditTitle = () => {
    const obj = {};
    this.actionOptions.forEach(item => {
      if (item.key == this.actionType) {
        obj.title = item.key.includes('d-') ? 'Duplicate variants' : item.name;
        obj.tip = item.tip;
      }
    });
    return obj;
  };

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys.length == 0) {
        this.disabledCopyOption();
      }
      this.setState({
        selectedRows: [...selectedRowKeys]
      });
      this.selectedRows = selectedRows;
    }
  };

  disabledCopyOption = () => {
    this.actionOptions.forEach(item => {
      if (item.key.includes('d-')) {
        item.disabled = true;
      }
    });
  };

  currentSku = null; // 设置图片的variant
  selectHead = false; // 设置主图还是variant的图片
  selectVariantRowIds = [];
  selectVariantValue = '';
  disabledFlag = false; // 手否禁用表单
  collections = [];
  tags = '';
  imageList = [];
  imageMap = {};
  imageSrcMap = {};
  variantMap = {};
  skuSame = true; // 是否和aliSku相同boolean
  exampleSku = null;
  selectedRows = [];
  actionType = '';
  actionValue = '';
  copykey = '';
  rowId = 0;
  copykeyIndex = null;
  keys = {
    price: 'price',
    compare_at_price: 'compare_at_price',
    aeStock: 'aeStock'
  };
  actionOptions = [
    {
      key: 'price',
      name: intl.get('product_edit.change_price'),
      tip: intl.get('product_edit.new_price'),
      disabled: false
    },
    {
      key: 'compare_at_price',
      name: intl.get('product_edit.change_compare_at_price'),
      tip: intl.get('product_edit.new_compare_price'),
      disabled: false
    },
    {
      key: 'delete',
      name: intl.get('product_edit.remove_sku'),
      tip: intl.get('product_edit.delete_selected_variant_tip'),
      disabled: false
    },
    {
      key: 'aeStock',
      name: intl.get('product_edit.change_stock'),
      tip: intl.get('product_edit.new_stock'),
      disabled: false
    }
  ];

  // 弹出选择主图
  showSelectHeadImg = () => {
    this.selectHead = true;
    this.setState({
      bShowModal: true
    });
  };

  // 把选择的图给主图
  selectImageToHead = (image = {}) => {
    const { producteDetail } = this.state;

    producteDetail.detail.image.src = image.src;

    const data = { ...producteDetail };
    this.setState({
      producteDetail: data,
      showImages: [...this.getImages(data)],
      bShowModal: false
    });
  };

  // 删除图片
  deleteImage = item => {
    const { showImages } = this.state;

    const arr = showImages.filter(image => item.src != image.src);

    this.setState({
      showImages: [...arr]
    });
  };

  // 渲染price文案和跳转逻辑
  renderPriceText = () => {
    const { 
      priceRuleStatus,
      settingStatus,
      settingType
    } = this.state;

    if(!settingStatus || settingType === 1){
      return <>{intl.getHTML('setting.priceUpdate.to_setting')}</>
    }

    if(!priceRuleStatus){
      return <a href="/app/settings?id=5">{intl.get('setting.priceUpdate.to_price')}</a>
    }

    return intl.get('setting.priceUpdate.cost_change');
  }

  switchChange = (e) => {
    this.setState({
      priceChecked: e,
    }, () => {
      this.setPrice(e)
    })
  }

  setPrice = (e) => {
    const { producteDetail: {id, storeId} = producteDetail } = this.props;

    const handleType = e ? 1 : 2;
    
    setPriceCheck({data:{
      product_id: id,
      store_id: storeId,
      sync_able: handleType
    }})
  } 

  render() {
    const {
      form,
      updateProductLoading,
      onHide,
      removeProduct,
      copyLink,
      storeCurrency,
      storeId
      // producteDetail: originalProductDetail
    } = this.props;
    // 是否是bargain商品池自营商品 用于物流十日达的处理
    // const isTendays = originalProductDetail?.isTendays;

    const { getFieldDecorator } = form;

    const {
      producteDetail,
      showImages,
      bShowModal,
      selectedRows,
      showEditSku,
      showDeleteSku,
      warningTips,
      packageType,
      priceRuleStatus,
      settingStatus,
      settingType,
      priceChecked
    } = this.state;

    const {
      detail: { image = {} },
      ext = {}
    } = producteDetail;

    const { map_type = 0 } = ext
    let currencyCode;
    let currencySymbol;
    let isNotUSD;
    let currencyRates;
    if (storeCurrency[storeId]) {
      currencyCode = storeCurrency[storeId].currency_code || 'USD';
      currencySymbol = currencySymbolMap[currencyCode];
      isNotUSD = currencyCode !== 'USD';
      currencyRates = storeCurrency[storeId].rates_value;
      if (storeCurrency[storeId].currency_code === undefined) {
        currencySymbol = currencySymbolMap[storeCurrency[storeId].currency || 'USD'];
      }
      if (storeCurrency[storeId].currency === undefined) {
        currencySymbol = currencySymbolMap[storeCurrency[storeId].currency_code || 'USD'];
      }
    }

    this.descriptionHtml = producteDetail.detail
      ? producteDetail.detail.body_html
      : '';

    if (selectedRows.toString() != this.selectVariantRowIds.toString()) {
      this.selectVariantValue = '';
      this.selectVariantRowIds = [];
    }

    // const isShowId = producteDetail.ext.standard

    let isSameSupplier = []
    if(producteDetail?.ext?.standard){
      Object.values(producteDetail?.ext?.standard).forEach(b => {
       const { is_default, variant_supply = [] } = b;
       isSameSupplier.push(variant_supply[0].supply[0].platform_product_id);
     });
    }
    const len = new Set(isSameSupplier).size

    const getSupplyTypeHandler = ()=>{
      if(producteDetail?.ext?.map_type === 6){
        return 'From Agency'
      }
      if(producteDetail.suppliers?.length === 1){
        if(producteDetail.suppliers[0] === 1){
          return null
        }
        if(producteDetail.suppliers[0] === 2){
          return intl.get('product_edit.from_aliexpress')
        }else{
          return intl.get('product_edit.from_tmall')
        }
      }else if(producteDetail.suppliers?.length === 2 && producteDetail.suppliers.sort()[0] === 2 &&
      producteDetail.suppliers.sort()[1] === 4){
        return 'From AliExpress,Tmall'
      }
    }

    const operations = (
      <div className="openbtn">
        {producteDetail.platform_type == 1 &&
        this.aePid &&
        producteDetail?.ext?.map_type === 1 ? (
          <>
            <a
              href={getSupplierUrl(producteDetail?.supplier_type,this.aePid)}
              target="_blank"
            >
              <Button data="dx">
              {intl.get('product_edit.open_on_supplier')}
              </Button>
            </a>
            <span />
          </>
        ) : null}
        {producteDetail.is_pub ? (
          <a
            role="button"
            tab-index="0"
            onClick={() => {
              copyLink(producteDetail);
            }}
          >
            <Button data="dx">
            {intl.get('product.product.open_in_shopify')}
            </Button>
          </a>
        ) : (
          <a
            role="button"
            tab-index="0"
            onClick={() => {
              this.onSubmit(true);
            }}
          >
            <Button data="dx">
            {intl.get('product.product.push_to_shopify')}
            </Button>
          </a>
        )}
      </div>
    );

    return (
      <>
        <div className="btn_t">
          <div className="l">{operations}</div>
          <div className="r">
            <Button
              size="large"
              data="bk"
              onClick={() => {
                removeProduct([producteDetail.id], producteDetail.status);
              }}
            >
              {intl.get('public.delete')}
            </Button>
          </div>
        </div>
        {this.getProdLoading ? (
          <div className={styles.loadingSpin}>
            <Spin />
          </div>
        ) : (
          <Form layout="vertical" className={styles.productForm}>
            {warningTips ? (
              <Alert
                message={intl.get(
                  'order.order_product.shipping_dialog.overrun_warning'
                )}
                type="warning"
                showIcon
              />
            ) : null}
            <Tabs
              tabPosition="top"
              animated={{ inkBar: false, tabPane: false }}
            >
              <TabPane tab={intl.get('product_edit.product')} key="product">
                <Row gutter={24}>
                  <Col span={5}>
                    <div
                      className={`${styles.headImage} ${
                        this.disabledFlag ? styles.headImageNoEdit : ''
                      }`}
                    >
                      <div className="ant-upload-list ant-upload-list-picture-card">
                        <div className="ant-upload-list-item">
                          <div className="ant-upload-list-item-info">
                            <span>
                              <a
                                className="ant-upload-list-item-thumbnail"
                                href={
                                  replaceUrlToHttps(image.src) || defaultImg
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={
                                    replaceUrlToHttps(image.src) || defaultImg
                                  }
                                  alt=""
                                  onError={e => {
                                    e.target.onerror = null;
                                    e.target.src = defaultImg;
                                  }}
                                />
                              </a>
                              <a
                                className="ant-upload-list-item-name"
                                href={
                                  replaceUrlToHttps(image.src) || defaultImg
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              />
                            </span>
                          </div>
                          {producteDetail.status != 1 ? (
                            <span className="ant-upload-list-item-actions">
                              <Icon
                                type="edit"
                                onClick={this.showSelectHeadImg}
                              />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={19}>
                    <Form.Item
                      label={intl.get('product_edit.title')}
                      extra={
                        <p className={styles.productFrom}>
                          {getSupplyTypeHandler()}
                        </p>
                      }
                    >
                      {getFieldDecorator('title', {
                        initialValue: producteDetail.detail.title || '',
                        rules: [
                          {
                            required: false,
                            message: intl.get('product_edit.input_title')
                          }
                        ]
                      })(
                        <Input.TextArea
                          maxLength={225}
                          disabled={this.disabledFlag}
                        />
                      )}
                    </Form.Item>
                    <Form.Item label="Organization">
                      <OrganizationNoEdit
                        producteDetail={producteDetail}
                        disabled={this.disabledFlag}
                      />
                    </Form.Item>
                    {producteDetail.is_pub ? (
                      <div className="Origin_info">
                        <div className="iconYu">
                          <i class="material-icons">error_outline</i>
                        </div>
                        <div className="textYu">
                          {intl.get('product_edit.no_edit')}
                        </div>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </TabPane>

              <TabPane
                tab={intl.getHTML('product_edit.variants', {
                  allvariants: producteDetail.detail.variants.length
                })}
                key="variants"
              >
                <Form.Item>
                  {packageType && ![6].includes(map_type) && (
                    <div className={styles.priceChange}>
                      {this.renderPriceText()}
                      <Switch
                        style={{ margin: '0 16px 0 12px' }}
                        // 禁用状态：Priceing 和自动更新同时打开才会能选择，否则就置灰并有对应提示
                        disabled={
                          !priceRuleStatus ||
                          !settingStatus ||
                          settingType === 1
                        }
                        checked={priceChecked}
                        onChange={this.switchChange}
                      />
                    </div>
                  )}
                  <Table
                    columns={this.getColumns(
                      currencyCode,
                      currencySymbol,
                      isNotUSD,
                      currencyRates,
                      map_type
                    )}
                    rowKey="rowId"
                    pagination={false}
                    dataSource={producteDetail.detail.variants}
                  />
                </Form.Item>
              </TabPane>
              <TabPane
                tab={intl.get('product_edit.description')}
                key="description"
              >
                <div
                  className={styles.descriptionContWrap}
                  dangerouslySetInnerHTML={{ __html: this.descriptionHtml }}
                />
              </TabPane>
              <TabPane
                tab={intl.getHTML('product_edit.checkImages', {
                  allImages: showImages.length
                })}
                key="images"
              >
                <Form.Item>
                  <div>
                    <ImagesList
                      data={showImages}
                      product_id={producteDetail.id}
                      deleteImage={this.deleteImage}
                      is_pub={producteDetail.is_pub}
                    />
                  </div>
                </Form.Item>
              </TabPane>
              <TabPane tab={intl.get('product_edit.shipping')} key="shipping">
                {![6].includes(map_type) ? (
                  <ShippingDetail
                    id={producteDetail.detail && producteDetail.detail.id}
                    data={this.props.producteDetail}
                    ship_to={this.props.producteDetail.prod.ship_to}
                  />
                ) : (
                  <AgencyShippingDetail
                    id={producteDetail.detail && producteDetail.detail.id}
                    data={this.props.producteDetail}
                    ship_to={this.props.producteDetail.prod.ship_to}
                  />
                )}
              </TabPane>
            </Tabs>
          </Form>
        )}

        {bShowModal ? (
          <Modal
            title={intl.getHTML('product_edit.checkImages', {
              allImages: showImages.length
            })}
            width={721}
            visible={bShowModal}
            onCancel={this.hideChangeImage}
            footer={false}
          >
            <SelectImageManagement
              params={{ product_id: producteDetail.id }}
              data={showImages}
              onOk={
                this.selectHead ? this.selectImageToHead : this.selectImageToSku
              }
              onCancel={this.hideChangeImage}
            />
          </Modal>
        ) : null}

        {showEditSku ? (
          <Modal
            title={this.getEditTitle().title}
            width={700}
            visible={showEditSku}
            onCancel={this.hideEditSku}
            onOk={this.saveEditSku}
            maskClosable={false}
            okText={intl.get('public.save')}
            cancelText={intl.get('public.cancel')}
          >
            <p>{this.getEditTitle().tip}</p>
            {this.actionType == 'price' ||
            this.actionType == 'cost' ||
            this.actionType == 'discount' ? (
              <InputNumber
                precision={2}
                min={0}
                style={{ width: '100%' }}
                onChange={v => {
                  this.editSkuVal(v);
                }}
              />
            ) : (
              <InputNumber
                style={{ width: '100%' }}
                onChange={e => {
                  this.editSkuVal(e);
                }}
              />
            )}
          </Modal>
        ) : null}

        {showDeleteSku ? (
          <Modal
            title={intl.get('product_edit.delete_variant_tip')}
            width={700}
            visible={showDeleteSku}
            onCancel={this.hideDeleteSku}
            onOk={this.deleteSku}
            okType="danger"
            maskClosable={false}
            okText={intl.get('public.delete')}
            cancelText={intl.get('public.cancel')}
          >
            <p>{intl.get('product_edit.cannot_recover')}</p>
          </Modal>
        ) : null}
      </>
    );
  }
}
