import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'dva';

import { Alert } from 'antd';

import { Get, Post } from 'utils/request';
import styles from './ProductErrorTip.less';

import migrateClose from 'assets/myproduct/close.png';
import migrateTip from 'assets/myproduct/migratetip.png';

import intl from 'react-intl-universal';

import { getOberloReport, oberloReport } from 'utils/utils'

const ProductErrorTip = ({
  dispatch,
  userInfo,
  lang,
  oberlouser,
  isoberlouser,
  OstoreInfo
}) => {
  const [errorTip, setErrorTip] = useState(false);
  const [failNum, setFail] = useState(0)

  useEffect(()=>{
    if(OstoreInfo?.last_ae_not_available){
      setFail(OstoreInfo?.last_ae_not_available)
    }
  },[OstoreInfo])

  useEffect(() => {
    if (isoberlouser) {
      getOberloReport('is_close_product_error').then(res => {
        if (res.value == 'true') {
          setErrorTip(false);
        } else {
          if(OstoreInfo?.last_ae_not_available){
            setErrorTip(true);
          }else{
            setErrorTip(false);
          }
        }
      });
    }
  },[OstoreInfo]);

  const handleCloseTip = () => {
    oberloReport('is_close_product_error', 'true');
  };

  return (
    <>
      {errorTip ? (
        <Alert
          className={styles.migrateTip}
          message={intl.getHTML('public.oberlo_product_error_tip',{
            num:failNum
          })}
          showIcon
          icon={
            <>
              <img className={styles.tip} src={migrateTip} alt="" />
            </>
          }
          closeText={
            <>
              <img className={styles.close} src={migrateClose} alt="" />
            </>
          }
          afterClose={handleCloseTip}
        />
      ) : null}
    </>
  );
};

export default connect(({ login, global, user, auth, topBtn }) => ({
  userInfo: login.userInfo,
  lang: global.lang,
  isBindAe: global.isBindAe,
  oberlouser: auth?.migrationInfo,
  isoberlouser: auth?.isOberloUser,
  OstoreInfo: auth?.OstoreInfo
}))(React.memo(ProductErrorTip));
