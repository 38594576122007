import React, { PureComponent } from 'react';
import { connect } from 'dva';
import qs from 'querystring';
import {
  Card,
  List,
  Button,
  Checkbox,
  Icon,
  Tooltip,
  Popover,
  Spin,
  Modal,
  Input,
  Drawer
} from 'antd';

import MoreActionGuide from 'components/FilterDrawer/MoreActionGuide';

import className from 'classnames';
import intl from 'react-intl-universal';
import round from 'lodash/round';

import RecommendTag from '../Order/component/RecommendTag';
import getAdminRole from 'utils/getAdminRole';
import { getStockAndCost } from './util';
import { replaceUrlToHttps, getSupplierUrl } from '../../utils/utils';
import { currencySymbolMap } from '../../common/sysconfig';
import { ProductCardTitles, ProductCardMoreAction } from 'features';

import { webCustomCollect, CommonDataCollect } from 'utils/utils';

import countryStyles from 'common/country_icon.js';

import styles from './Product.less';
import styles3 from './MyProduct.less';
import './Priduct.css';
import './countryIcons.css';

import mappingIcon from 'assets/newImportList/mapping_icon.png';
import isDsersProductIco from '../../assets/product/Verified.png';
import aeProductIco from '../../assets/product/AE.png';
import defaultImg from '../../assets/default-image.jpg';
import tmalllogo from 'assets/tmall_logo.svg';
import ae from 'assets/icon-ae.jpg';
import FindProductButton from 'components/FindProductButton';
import { TitleIcon } from 'components/BargainProductTags';

const ListItem = List.Item;
const { Meta } = Card;

const bannerTrickConfig = {
  'track-type': 'productBargain',
  'track-name': 'supplyBannerV3',
  'track-metadata-position': 'myProduct'
};

@connect(({ global, login,user }) => ({
  storeCurrency: global.storeCurrency,
  userInfo: login.userInfo,
  importFlag:user,
  customizeCurrency: login.customizeCurrency
}))
export default class Product extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      costLoading: true,
      openFunctionVisible: false,
      showShip: true,
    };

    this.hasBannerV3Access = window.DSERS_BANNER_V3 || getAdminRole();
  }
  costDetailRef = React.createRef(null)
  costData = [];

  handleClickOther = (e)=>{
    if(!this.costDetailRef.current?.contains(e.target)){
      this.setState({
        openCostlist:false
      })
      return
    }
      this.setState({
        openCostlist:true
      })
    
  }
  componentDidMount(){
    document.addEventListener('click',this.handleClickOther,false)
  }
  // 获取不是基础mapping的cost
  getCost = product => {
    this.setState({
      openCostlist:true
    })
    this.props.dispatch({
      type: 'productmy/allCost',
      payload: {
        data: {
          id: product.id
        },
        callback: d => {
          if (d.code === 2000) {
            this.costData = d.data;
            this.setState({
              costLoading: false
            });
          }
        }
      }
    });
  };

  dotHandler(type){
    if(type === 4){
      CommonDataCollect({
        action:'myproduct_card_standard_click_tmall_link',
        event_type:'my_product_tmall'
      })
    }
  }

  renderCost = (currencyCode, currencySymbol, isNotUSD, currencyRates) => {
    const arr = [];
    const { product = {} } = this.props;

    let customizeCurrency = 0
    if(this.props?.customizeCurrency?.storeId === product.storeId){
      customizeCurrency = this.props?.customizeCurrency?.currency
    }
    
    if (!this.costData || this.costData?.length == 0) {
      return (
        <Spin spinning={this.state.costLoading}>
          <div className={styles.costDefault}>
            {intl.get('product.cost_ible')}
          </div>
        </Spin>
      );
    }

    this.costData.forEach(prod => {
      const cost = getStockAndCost(prod, 1, {}, product);
      const p = (
        <li className={styles.costCon} key={prod.product_id}>
          <img src={replaceUrlToHttps(prod.image_src)} />
          <p className={styles.costTitle}>
            <a
              onClick={()=>this.dotHandler(prod?.supplier_type)}
              href={getSupplierUrl(prod?.supplier_type,prod.product_id_str || prod.product_id)}
              target="_blank"
            >
              <img src={prod?.supplier_type === 4 ? tmalllogo : ae} alt="" />{' '}
              {prod.title}
            </a>
            <div>
              <p className={styles.costValue}>
                {`${intl.get('product.product.cost')}: $ ${cost.cost || '--'}`}
              </p>
              {isNotUSD ? (
                <p className={styles.currencyValue}>
                  {cost.minCost === cost.maxCost
                    ? `${currencySymbol} ${round(
                        cost.minCost * (customizeCurrency || currencyRates),
                        2
                      ) || '--'}`
                    : `${currencySymbol} ${round(
                        cost.minCost * (customizeCurrency || currencyRates),
                        2
                      ) || '--'}~${currencySymbol}${round(
                        cost.maxCost * (customizeCurrency || currencyRates),
                        2
                      ) || '--'}`}
                </p>
              ) : null}
            </div>
          </p>
        </li>
      );
      arr.push(p);
    });
    // if(!arr.length){
    //   return <li>none</li>
    // }
    return arr;
  };

  description = (
    product,
    currencyCode,
    currencySymbol,
    isNotUSD,
    currencyRates,
    showShip
  ) => {
    const {
      prod: {
        ext: { map_type }
      }
    } = product;
    let isSameSupplier = [];

    if (product?.prod?.ext?.standard) {
      Object.values(product?.prod?.ext?.standard).forEach(b => {
        const { is_default, variant_supply = [] } = b;
        isSameSupplier.push(variant_supply[0].supply[0].platform_product_id);
      });
    }
    const len = new Set(isSameSupplier).size;


    if (map_type === 6) {
      return (
        <>
          {this.title(product)}
          <p className={styles3.cost}>
            <span className={styles3.value}>
              {`${intl.get('product.product.cost')} $${
                String(product.cost)?.includes('~')
                  ? String(product.cost).replace('~', ' ~ ')
                  : product.cost
              }`}
            </span>
          </p>
        </>
      );
    }

    return (
      <span  ref={this.costDetailRef}>
      {this.title(product)}
        {map_type == 1 || (map_type == 5 && !(len > 1)) || !map_type ? (
          <>
            <p className={styles3.cost}>
              <span className={styles3.value}>
                {`${intl.get('product.product.cost')} $${
                  String(product.cost)?.includes('~')
                    ? String(product.cost).replace('~', ' ~ ')
                    : product.cost
                }`}
              </span>
              <div className={styles3.icon}>
                {!product?.suppliers?.includes(4) ? <Tooltip title={intl.get('product.product.piece_tip')}>
                  <span>
                    <i class="material-icons notranslate">help_outline</i>
                  </span>
                </Tooltip>:null}
              </div>
              <span
                style={{ display: showShip ? 'block' : 'none' }}
                className={`css_flag font css_${
                  countryStyles[product.prod.ship_to]
                    ? countryStyles[product.prod.ship_to][0]
                    : countryStyles['US'][0]
                }`}
              >
                <i>
                  {intl.getHTML('product.price_for')}{' '}
                  {countryStyles[product.prod.ship_to]
                    ? countryStyles[product.prod.ship_to][1]
                    : countryStyles['US'][1]}
                </i>
              </span>
            </p>
          </>
        ) : (
          <>
            <p className={styles3.cost}>
              <span className={styles.value}>
                {intl.get('product.product.cost')}&nbsp;
                <Popover
                  overlayClassName="multipleTag"
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  visible={this.state.openCostlist}
                  content={
                    <ul className={styles.multipleCost} >
                      {this.renderCost(
                        currencyCode,
                        currencySymbol,
                        isNotUSD,
                        currencyRates
                      )}
                    </ul>
                  }
                  trigger="click"
                  placement="bottom"
                  autoAdjustOverflow={false}
                >
                  <a
                    role="button"
                    tab-index="0"
                    onClick={e => this.getCost(product)}
                    className={styles3.multiple}
                  >
                    {intl.get('product.product.multiple')}{' '}
                    <img
                      style={{ marginLeft: 5, marginTop: 15 }}
                      src={require('../../assets/newImportList/icon_expand_more-24px.png')}
                    ></img>
                  </a>
                </Popover>
              </span>
              <span
                style={{ display: showShip ? 'block' : 'none' }}
                className={`css_flag font css_${
                  countryStyles[product.prod.ship_to]
                    ? countryStyles[product.prod.ship_to][0]
                    : countryStyles['US'][0]
                }`}
              >
                <i>
                  {intl.getHTML('product.price_for')}{' '}
                  {countryStyles[product.prod.ship_to]
                    ? countryStyles[product.prod.ship_to][1]
                    : countryStyles['US'][1]}
                </i>
              </span>
            </p>
            <p className={styles.price} />
          </>
        )}
      </span>
    );
  };

  getABC = (product, currencyCode, currencySymbol, isNotUSD, currencyRates) => {
    const {
      prod: {
        ext: { map_type = 0 }
      }
    } = product;
    let isSameSupplier = [];
    if (product?.prod?.ext?.standard) {
      Object.values(product?.prod?.ext?.standard).forEach(b => {
        const { is_default, variant_supply = [] } = b;
        isSameSupplier.push(variant_supply[0].supply[0].platform_product_id);
      });
    }
    const len = new Set(isSameSupplier).size;

    let customizeCurrency = 0
    if(this.props?.customizeCurrency?.storeId === product.storeId){
      customizeCurrency = this.props?.customizeCurrency?.currency
    }

    return (
      <>
        {map_type == 1 || (map_type == 5 && !(len > 1)) || !map_type || map_type === 6 ? (
          <>
            {isNotUSD && product.minCost ? (
              <p className={styles3.pricely}>
                <span className={styles3.row}>{`${intl.get(
                  'product.product.cost'
                )} `}</span>
                {product.minCost === product.maxCost
                  ? `${currencySymbol}${round(
                      product.minCost * (customizeCurrency || currencyRates),
                      2
                    )}`
                  : `${currencySymbol}${round(
                      product.minCost * (customizeCurrency || currencyRates),
                      2
                    )}~${round(product.maxCost * (customizeCurrency || currencyRates), 2)}`}
              </p>
            ) : null}
            <p className={styles3.price}>
              <span className={styles3.row}>
                {intl.get('product.product.price')}:
              </span>{' '}
              {currencySymbol}
              {product.price}
              {product.price2 ? `~${product.price2}` : null}
            </p>
          </>
        ) : (
          <></>
        )}
        {product.platform_type == 2 || (map_type >= 2 && len > 1) || [0,6].includes(map_type) ? (
          <div className={styles3.ext} />
        ) : (
          <div className={styles3.ext}>
            <p className={styles3.stock}>
              <span className={styles3.row}>
                {intl.get('product.product.stock')}:
              </span>{' '}
              {product.stock_count}
            </p>
          </div>
        )}
      </>
    );
  };

  getNotify = prod => {
    const { notify } = prod;
    const {
      product = {},
      showSupplier,
      showNotificationDetail,
      showBanner
    } = this.props;
    return (
      <div>
        {notify.map(item => {
          if (!item.type) {
            return null;
          }
          if (item.type == 2) {
            return (
              <a
                role="button"
                tab-index="0"
                className={styles3.notify}
                onClick={e => showSupplier(product, showBanner, true, item)}
                key={item.id}
              >
                <i class="material-icons notranslate">error_outline</i>
                {intl.get('notification.variants_out_of_stock')}
              </a>
            );
          } else if (item.type == 3) {
            if (
              product?.prod?.notification?.product_not_found === 'true' ||
              product?.prod?.notification?.product_out_of_stock === 'true'
            ) {
              return null;
            } else {
              return (
                <a
                  role="button"
                  tab-index="0"
                  className={styles3.notify}
                  onClick={e => showSupplier(product, showBanner, true, item)}
                  key={item.id}
                >
                  <i class="material-icons notranslate">error_outline</i>
                  {intl.get('notification.variants_not_of_stock')}
                </a>
              );
            }
          } else if (item.type == 5) {
            return (
              <a
                role="button"
                className={styles3.notify}
                tab-index="0"
                onClick={e => showNotificationDetail(item)}
                key={item.id}
              >
                <i class="material-icons notranslate">error_outline</i>
                {intl.get('notification.product_price_has_changed')}
              </a>
            );
          } else {
            return (
              <a
                role="button"
                tab-index="0"
                className={styles3.notify}
                onClick={e => showNotificationDetail(item)}
                key={item.id}
              >
                <i class="material-icons notranslate">error_outline</i>
                {intl.get('product.product.no_out_stock')}
              </a>
            );
          }
        })}
      </div>
    );
  };

  getAeProductNotify = product => {
    if (product?.prod?.notification?.product_not_found === 'true') {
      return (
        <a
          role="button"
          tab-index="0"
          className={styles3.notify}
          // key={item.id}
        >
          <i class="material-icons notranslate">error_outline</i>
          {intl.get('notification.product_not_found')}
        </a>
      );
    } else if (product?.prod?.notification?.product_out_of_stock === 'true') {
      return (
        <a
          role="button"
          tab-index="0"
          className={styles3.notify}
          // key={item.id}
        >
          <i class="material-icons notranslate">error_outline</i>
          {intl.get('notification.product_out_of_stock_tip')}
        </a>
      );
    }
  };

  handleBannerClick = item => {
    const { product, onBannerItemClick } = this.props;
    if (product.prod && product.prod.detail) {
      onBannerItemClick(product.prod.detail.id, item);
    }
  };

  openFunctionHandler = () => {
    this.setState({
      openFunctionVisible: true
    });
  };

  closeFunctionHandler = () => {
    this.setState({
      openFunctionVisible: false
    });
  };

  leaveCardHandler = () => {
    this.setState({
      showShip: true
    });
  };

  enterCardHandler = () => {
    this.setState({
      showShip: false
    });
  };

  moreFunctionHandler = id => {
    const { product = {}, showEye, showDetail, removeProduct } = this.props;
    switch (id) {
      case 1:
        if(!product.suppliers?.length && product?.supplier_type === 2){
          window.dtag.set({
            event:'click',
            module:'Aliexpress',
            action:'Aliexpress_figure_search',
            custom_data:{
              'click_type': 'go_supplier_optimizer_product_card'
            }
          })
          const paramObj = {
            url: product.image,
            ref: 'my_product',
            id: product.ae_pid,
            store_id: localStorage.getItem('storeId')
          };
          const paramStr = qs.stringify(paramObj);
          const link = `/app/supplier_optimizer?${paramStr}`;
          window.open(link);
          return
        }
        if((!product.suppliers?.length && window.TMALL_USER) || (product.suppliers.includes(1) && window.TMALL_USER) ){
          window.dtag.set({
            event:'click',
            module:'Aliexpress',
            action:'Aliexpress_figure_search',
            custom_data:{
              'click_type': 'go_supplier_optimizer_product_card'
            }
          })
          const paramObjTmall ={
            url:  product.image,
            ref: 'import_list',
            supplierType:"unmap",
            store_id: localStorage.getItem('storeId')
          }
          const paramStrTmall = qs.stringify(paramObjTmall)
          window.open(`/app/supplier_optimizer?${paramStrTmall}`)
          return
        }
        if(product.suppliers.includes(4)){
          window.dtag.set({
            event:'click',
            module:'tmall',
            action:'tmall_figure_search',
            custom_data:{
              'click_type': 'go_supplier_optimizer_product_card'
            }
          })
          window.dtag.set({
            event:'click',
            module:'Aliexpress',
            action:'Aliexpress_figure_search',
            custom_data:{
              'click_type': 'go_supplier_optimizer_product_card'
            }
          })
          const paramObjTmall ={
            url:`https://www.dsers.com/app/tmallproduct/${product.ae_pid}?appId=159831084`,
            ref: 'import_list',
            supplierType:"tmall",
            store_id: localStorage.getItem('storeId')
          }
          const paramStrTmall = qs.stringify(paramObjTmall)
          window.open(`/app/supplier_optimizer?${paramStrTmall}`)
          return
        }
        const paramObj = {
          url: product.image,
          ref: 'import_list',
          id: product.ae_pid,
          store_id: localStorage.getItem('storeId')
        };
        const paramStr = qs.stringify(paramObj);
        const link = `/app/supplier_optimizer?${paramStr}`;
        window.open(link);
        break;
      case 2:
        showDetail(product);
        break;
      case 3:
        showEye([product]);
        break;
      case 4:
        this.setState({
          replaceStatus:true
        })
        this.props.openReplace(product)
        break;
      case 5:
        removeProduct([product.id], product.is_pub);
        break;
      default:
        break;
    }
  };



  openCostDetail = ()=>{
    this.setState({
      openCostlist:true
    })
    this.getCost(this.props.product)
  }
  title =(product)=> {
    return (
      <span style={{height:'32px'}} ref={this.costDetailRef}>
        <ProductCardTitles
          openCostDetail={this.openCostDetail}
          type="myproduct"
          product={product}
        />
      </span>
    );
  }

  render() {
    const {
      product = {},
      push,
      removeProduct,
      showSupplier,
      showDetail,
      copyLink,
      showEye,
      storeId,
      showBanner,
      supplyBannerExtraData,
      storeCurrency,
      firstId
    } = this.props;
    let isFirst = false;
    if (product) {
      isFirst = firstId === product.id;
    }
    const { prod } = product;
    const { ext } = prod;
    const { map_type = 0, agencies = {} } = ext;

    const moreActionOptions = [
      {
        id: 1,
        name: 'Supplier Optimizer',
        name2: 'Supplier Optimizer',
        hidden:
          (map_type === 6 && Object.keys(agencies).length)
            ? true
            : false,
        icon: 'search',
        disabled:!product.image.includes('https:'),
        icon2: 'search'
      },
      {
        id: 2,
        name: 'Check details',
        name2: 'Check details',
        icon: 'plagiarism',
        icon2: 'plagiarism'
      },
      {
        id: 3,
        name: 'Hide product',
        name2: 'Unhide product',
        icon: 'remove_red_eye',
        icon2: 'visibility_off'
      },
      {
        id: 4,
        name: 'Replace Product',
        name2: 'Replace Product',
        disabled: this.state.disReplaceProduct,
        icon: 'move_up',
        icon2: 'move_up'
      },
      {
        id: 5,
        name: 'Delete',
        name2: 'Delete',
        icon: 'delete',
        icon2: 'delete'
      }
    ];

    let currencyCode;
    let currencySymbol;
    let isNotUSD;
    let currencyRates;

    if (storeCurrency[storeId]) {
      currencyCode = storeCurrency[storeId].currency_code || 'USD';
      currencySymbol = currencySymbolMap[currencyCode];
      isNotUSD = currencyCode !== 'USD';
      currencyRates = storeCurrency[storeId].rates_value;
    }

    // const isAmi = this.props.importFlag.newUserInfo.created_at > window.NEW_INFO_TIME && JSON.parse(localStorage.getItem('store'))?.import_flag == 0 && this.props.index === 0
    // suppliers 包含[1] 有映射关系 反之没有
    const isAmi = this.props.importFlag.newUserInfo.created_at > window.NEW_INFO_TIME && 
      (this.props.product?.prod?.suppliers?.includes(1) || this.props.product?.prod?.supplier_type === 1);

    return (
      <div>
        <ListItem
          onMouseLeave={this.leaveCardHandler}
          onMouseEnter={this.enterCardHandler}
        >
          <Card
            bordered={false}
            hoverable
            actions={
              true && !this.props.product.is_del
                ? newAction(
                    product,
                    removeProduct,
                    showSupplier,
                    showDetail,
                    copyLink,
                    showBanner,
                    showEye,
                    this.openFunctionHandler
                  )
                : actions(
                    product,
                    removeProduct,
                    showSupplier,
                    showDetail,
                    copyLink,
                    showBanner,
                    showEye
                  )
            }
            cover={
              <img
                style={{ width: 208, height: 208 }}
                alt={product.title}
                src={replaceUrlToHttps(product.image)}
                className={styles.pic}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = defaultImg;
                }}
              />
            }
            className={className(styles3.product, {
              [styles.onlineProduct]: product.is_pub,
              'find-product-action-item': true
            })}
            data-label="product-item"
          >
            <div className={className("transon_opt",{
              'transon_opt_Ami':isAmi && !product.is_del
            })} >           
              {true && !this.props.product.is_del ? (
                <Tooltip title={'Variants Mapping'}>
                  <p
                    className={styles3.mappingBtn}
                    style={{marginBottom:isAmi?0:'12px'}}
                    onClick={() => {
                      showSupplier(product, showBanner);
                      localStorage.setItem('mapping_dot', 'product');
                      webCustomCollect({
                        action: 'product_click_mapping',
                        custom_info: []
                      });
                    }}
                  >
                    <img src={mappingIcon} alt=""/>
                  </p>
                </Tooltip>
              ) : (
                <FindProductButton
                  image={product.image}
                  offset={product.is_pub && product.is_black_list === 2}
                  hidden={product.is_del}
                  pageRef="my_product"
                  isFirst={isFirst}
                />
              )}
            </div>
            <Meta
           style={{paddingTop:isAmi?'16px':'0px'}}
              // title={this.title(product)}
              description={this.description(
                product,
                currencyCode,
                currencySymbol,
                isNotUSD,
                currencyRates,
                this.state.showShip
              )}
            />
            {this.getABC(
              product,
              currencyCode,
              currencySymbol,
              isNotUSD,
              currencyRates
            )}
            {showBanner ? null : (
              <Checkbox value={product.id} className={styles3.checkbox} />
            )}
            <div className="topiwpcw" >
              {product.is_pub && product.is_black_list === 2 && !product.is_del ? (
                <span className={styles3.blackTip}>
                  {intl.get('product.product.hidden')}
                  {
                    ![6].includes(product.prod.ext.map_type) && (product?.suppliers.length === 1 &&
                      product?.suppliers[0] != 4) ? (
                      <Tooltip title={intl.get('product.product.tip_hidden')}>
                        <Icon type="question-circle" />
                      </Tooltip>
                    ) : null
                  }
                </span>
              ) : null}
              {product.is_del ? (
                <p className={styles3.delByShopifyTip}>
                  {intl.get('product.product.remove_from_shopify')}
                  {
                    ![6].includes(product.prod.ext.map_type) ? (
                  <Tooltip
                    title={intl.get('product.product.tip_deleted_on_Shopify')}
                  >
                    <Icon type="question-circle" />
                  </Tooltip>
                    ): null}
                </p>
              ) : null}
              {product.is_pub ? (
                <span></span>
              ) : (
                <p className={styles3.pushBtn}>
                  <Button
                    type="primary"
                    onClick={() => {
                      push([product.id], product.is_pub);
                    }}
                  >
                    {intl.get('product.product.push_to_shopify')}
                  </Button>
                </p>
              )}
              <div className={styles3.goodsExtra}>
                {showBanner ? (
                  <RecommendTag
                    data={
                      supplyBannerExtraData[
                        `${product.storeId}_${product.prod.detail.id}`
                      ]
                    }
                    onLinkClick={this.handleBannerClick}
                    style={{ top: -32, left: 10 }}
                    title={
                      this.hasBannerV3Access
                        ? intl.get('bargain.found_cheaper_one')
                        : intl.get('bargain.need_alternative')
                    }
                    showPoint
                    trickConfig={bannerTrickConfig}
                  />
                ) : null}
                {product.notify ? this.getNotify(product) : null}
                {product?.prod?.notification &&
                Object.values(product?.prod?.notification)?.length
                  ? this.getAeProductNotify(product)
                  : null}
              </div>
            </div>
          </Card>
          {this.props.index === this.props.guideIndex ? (
            <MoreActionGuide type="product"></MoreActionGuide>
          ) : null}
        </ListItem>
        <ProductCardMoreAction
          type={'my_product'}
          openFunctionVisible={this.state.openFunctionVisible}
          product={product}
          moreActionOptions={moreActionOptions}
          moreFunctionHandler={this.moreFunctionHandler}
          closeFunctionHandler={this.closeFunctionHandler}
        />
        
      </div>
    );
  }
}

Product.defaultProps = {
  showBanner: false
};



function newAction(
  product,
  removeProduct,
  showSupplier,
  showDetail,
  copyLink,
  showBanner,
  showEye,
  openFunctionHandler
) {
  return [
    <div className={styles.functionContainer}>
      <Tooltip
        className={styles.input}
        title={intl.get('product.product.open_in_shopify')}
        key="open"
      >
        <span onClick={() => copyLink(product)}>
          <i class="material-icons">input</i>
        </span>
      </Tooltip>

      <Tooltip
        className={styles.more}
        style={{ fontSize: 24 }}
        title={'More Action'}
      >
        <span>
          <i class="material-icons" onClick={openFunctionHandler}>
            more_vert
          </i>
        </span>
      </Tooltip>
    </div>
  ];
}

function actions(
  product,
  removeProduct,
  showSupplier,
  showDetail,
  copyLink,
  showBanner,
  showEye
) {
  if (product.is_del) {
    return [
      <Tooltip title={intl.get('product.product.delete')} key="delete">
        <span
          className="icon_center"
          onClick={() => {
            removeProduct([product.id], product.is_pub);
          }}
        >
          <i class="material-icons">delete</i>
        </span>
      </Tooltip>
    ];
  }

  const tools = [
    <Tooltip
      title={intl.get('product.product.check_details')}
      key="file-search"
    >
      <span onClick={() => showDetail(product)}>
        <i class="material-icons">plagiarism</i>
      </span>
    </Tooltip>,
    <Tooltip title={intl.get('product.product.open_in_shopify')} key="open">
      <span onClick={() => copyLink(product)}>
        <i class="material-icons">input</i>
      </span>
    </Tooltip>,
    <Tooltip
      title={
        product.is_black_list !== 2
          ? intl.get('product.product.hidden_open_tip')
          : intl.get('product.product.hidden_tip')
      }
      key="eye"
    >
      <span onClick={() => showEye([product])}>
        {product.is_black_list !== 2 ? (
          <i class="material-icons notranslate">remove_red_eye</i>
        ) : (
          <i class="material-icons notranslate">visibility_off</i>
        )}
      </span>
    </Tooltip>,
    <Tooltip title={intl.get('product.product.delete')} key="delete">
      <span
        onClick={() => {
          removeProduct([product.id], product.is_pub);
        }}
      >
        <i class="material-icons">delete</i>
      </span>
    </Tooltip>,
    <Tooltip title={intl.get('product.product.mapping_Page')} key="cluster">
      <span
        onClick={() => {
          showSupplier(product, showBanner);
          localStorage.setItem('mapping_dot', 'product');
          webCustomCollect({
            action: 'product_click_mapping',
            custom_info: []
          });
        }}
        className={styles3.iconMapping}
      ></span>
    </Tooltip>
  ];
  return tools;
}
